@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baloo+Bhai+2&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baloo+Bhai+2&family=Noto+Sans:ital,wght@1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap');

:root {
  --primary: #1d3883;
  --light: #d1d7e6;
  --dark: #212529;
  --gray: #878787;
  --dark-gray: #5c5a6c;
  --warning: #fed855;
  --yellow: #f0ac39;
  --danger: #ff2626;
}
::-webkit-scrollbar {
  width: 0.5em;
}
::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}
.eventDetail {
  /* height: 435px; */
}
::-webkit-scrollbar-thumb {
  background-color: rgba(112, 112, 112, 1);
}
/* .card-img-top:hover {
  transform: scale(2) ;
  
}
.card-img-top {
transition: all 0.3s ease-in;
} */


.flip-card {
  background-color: transparent;
  width: 300px;
  height: 435px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card.active .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  z-index: 100000000000000000;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #1d3883;
  color: white;
  transform: rotateY(180deg);
}
.open-button {
  padding: 3px 20px;
  border: none;
  cursor: pointer;
  opacity: 1;
  position: fixed;
  bottom: 8%;
  right: 0;
  color: #000;
  z-index: 1000;
  border: none;
  border-radius: 45px;

  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}
.open-button:hover {
  background-color: #2ee59d;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translateY(-7px);
}

body {
  width: 100%;
  height: 100%;
}
.btn-group-fab {
  position: fixed;
  width: 50px;
  height: auto;
  right: 20px;
  bottom: 20px;
}
.btn-group-fab div {
  position: relative;
  width: 100%;
  height: auto;
}
.btn-group-fab .btn {
  position: absolute;
  bottom: 0;
  border-radius: 50%;
  display: block;
  margin-bottom: 4px;
  width: 40px;
  height: 40px;
  margin: 4px auto;
}
.btn-group-fab .btn-main {
  width: 70px;
  height: 70px;
  right: 50%;
  margin-right: -25px;
  z-index: 9;
}
.btn-group-fab .btn-sub {
  bottom: 10px;
  z-index: 8;
  right: 50%;
  width: 60px;
  height: 60px;
  margin-right: -20px;
  -webkit-transition: all 2s;
  transition: all 0.5s;
}
.btn-group-fab.active .btn-sub:nth-child(2) {
  bottom: 90px;
}
.btn-group-fab.active .btn-sub:nth-child(3) {
  bottom: 170px;
}
.btn-group-fab.active .btn-sub:nth-child(4) {
  bottom: 250px;
}
.btn-group-fab .btn-sub:nth-child(5) {
  bottom: 210px;
}

.modal-backdrop {
  position: relative;
}
.recruiters {
  height: fit-content;
}
.homepage1 {
  display: none;
}

.homePage {
  /* background-image: url(../../assets/images/homepage.png) no-repeat ; */
  /* background-size: cover,contain; */
  /* background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center top; */
  /* background-attachment: fixed; */
  /* padding: 0;
    margin: 0;
    margin-top: -60px; */
  margin-top: -60px;
}
.homePage img {
  width: 100%;
}
.shapes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}
.shapes li {
  position: absolute;
  display: block;
  list-style: none;
  width: 100px;
  z-index: 0;
  /* box-shadow: 0rem 1rem .8rem rgba(0,0,0,.3)!important; */
  height: 100px;
  transition: 0.3s ease-in-out;
  /* background: rgba(0, 0, 0, 0.432); */
  /* animation: animate2 2s linear infinite; */
  /* bottom: 150px; */
}
.shapes li:nth-child(1) {
  /* z-index: 12; */
  animation-delay: 0s;
  width: 28px;
  height: 32px;
  top: 11rem;
  left: 87%;
  background-size: contain;
  background-image: url("../../assets/images/Polygon19.svg");
  background-repeat: no-repeat;
}
.shapes li:nth-child(1):hover {
  transform: scale(150%) rotate(20deg);
}
.shapes li:nth-child(2) {
  /* z-index: 12; */
  animation-delay: 0s;
  width: 37px;
  height: 42px;
  top: 21rem;
  left: 84%;
  background-size: contain;
  background-image: url("../../assets/images/Polygon\ 15.svg");
  background-repeat: no-repeat;
}
.shapes li:nth-child(2):hover {
  transform: scale(150%) translateY(30%) rotate(-120deg);
}
.shapes li:nth-child(3) {
  /* z-index: 12; */
  animation-delay: 0s;
  width: 30px;
  height: 20px;
  top: 24rem;
  left: 79%;
  background-size: contain;
  background-image: url("../../assets/images/Ellipse\ 23.svg");
  background-repeat: no-repeat;
}
.shapes li:nth-child(3):hover {
  transform: scale(130%) translateY(100%) rotate(-20deg);
}
.shapes li:nth-child(4) {
  /* z-index: 12; */
  animation-delay: 0s;
  width: 50px;
  height: 28px;
  top: 10rem;
  left: 75%;
  background-size: contain;
  background-image: url("../../assets/images/Polygon\ 15.svg");
  background-repeat: no-repeat;
}
.shapes li:nth-child(4):hover {
  transform: scale(200%) rotate(-20deg);
}
.shapes li:nth-child(5) {
  /* z-index: 12; */
  animation-delay: 0s;
  width: 50px;
  height: 20px;
  top: 9rem;
  /* left: 75%; */
  right: 30%;
  background-size: contain;
  background-image: url("../../assets/images/Ellipse\ 23.svg");
  background-repeat: no-repeat;
}
.shapes li:nth-child(5):hover {
  transform: scale(130%) translateY(100%) rotate(-20deg);
}
.shapes li:nth-child(6) {
  /* z-index: 12; */
  animation-delay: 0s;
  width: 50px;
  height: 26px;
  top: 7rem;
  /* left: 87%; */
  right: 60%;
  background-size: contain;
  background-image: url("../../assets/images/Polygon19.svg");
  background-repeat: no-repeat;
}
.shapes li:nth-child(6):hover {
  transform: scale(150%) rotate(20deg);
}
.registerPage {
  margin-top: 85px;
}
.shapes li:nth-child(7) {
  /* z-index: 0; */
  animation-delay: 0s;
  width: 50px;
  height: 42px;
  top: 19rem;
  /* left: 87%;
      bottom: 74%;
      right:75%; */
  background-size: contain;
  background-image: url("../../assets/images/Ellipse\ 29.svg");
  background-repeat: no-repeat;
}
.shapes li:nth-child(7):hover {
  transform: scale(150%) rotate(20deg);
}
.shapes li:nth-child(8) {
  /* z-index: 12; */
  animation-delay: 0s;
  width: 38px;
  height: 30px;
  top: 20rem;
  left: 30%;
  /* right:75%; */
  background-size: contain;
  background-image: url("../../assets/images/Polygon\ 15.svg");
  background-repeat: no-repeat;
}
.shapes li:nth-child(8):hover {
  transform: scale(200%) rotate(-20deg);
}
.shapes li:nth-child(9) {
  /* z-index: 12; */
  animation-delay: 0s;
  width: 50px;
  height: 22px;
  top: 11rem;
  left: 10%;
  /* right: 80%; */
  background-size: contain;
  background-image: url("../../assets/images/Ellipse\ 23.svg");
  background-repeat: no-repeat;
}
.shapes li:nth-child(9):hover {
  transform: scale(130%) translateY(100%) rotate(-20deg);
}
.shapes li:nth-child(10) {
  /* z-index: 12; */
  animation-delay: 0s;
  width: 50px;
  height: 42px;
  /* top: 17rem; */
  /* left: 87%; */
  bottom: 42%;
  right: 28rem;
  background-size: contain;
  background-image: url("../../assets/images/Ellipse\ 30.svg");
  background-repeat: no-repeat;
}
.shapes1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}
.shapes1 li {
  position: absolute;
  display: block;
  list-style: none;
  width: 100px;
  z-index: 12;
  /* box-shadow: 0rem 1rem .8rem rgba(0,0,0,.3)!important; */
  height: 100px;
  transition: 0.3s ease-in-out;
}
.shapes1 li:nth-child(1) {
  z-index: 12;
  animation-delay: 0s;
  width: 50px;
  height: 26px;
  /* top: 55rem;
      left:5%; */
  right: 76%;
  bottom: 49%;
  background-size: contain;
  background-image: url("../../assets/images/Polygon19.svg");
  background-repeat: no-repeat;
}
.shapes1 li:nth-child(1):hover {
  transform: scale(150%) rotate(20deg);
}
/* .shapes1 li:nth-child(2){
    z-index: 12;
    animation-delay: 0s;
    position: relative;
      width: 50px;
      height: 32px;
      top: 45rem;
      left:50%;
      background-size: contain;
      background-image: url("../../assets/images/Polygon19.svg") ;
      background-repeat: no-repeat;
  }
  .shapes1 li:nth-child(2):hover{
    transform: scale(150%) rotate(20deg);
  }
  .shapes1 li:nth-child(3){
    z-index: 12;
    animation-delay: 0s;
      width: 50px;
      height: 32px;
      top: 45rem;
      left: 20%;
      background-size: contain;
      background-image: url("../../assets/images/Polygon19.svg") ;
      background-repeat: no-repeat;
  }
  .shapes li:nth-child(3):hover{
    transform: scale(150%) rotate(20deg);
  }
  .shapes1 li:nth-child(4){
    z-index: 12;
    animation-delay: 0s;
      width: 50px;
      height: 32px;
      top: 45rem;
      left: 20%;
      background-size: contain;
      background-image: url("../../assets/images/Polygon19.svg") ;
      background-repeat: no-repeat;
  }
  .shapes li:nth-child(4):hover{
    transform: scale(150%) rotate(20deg);
  } */

.shapes2 {
  position: absolute;
  /* top: 100%; */
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}
.shapes2 li {
  position: absolute;
  display: block;
  list-style: none;
  width: 100px;
  z-index: 0;
  /* box-shadow: 0rem 1rem .8rem rgba(0,0,0,.3)!important; */
  height: 100px;
  transition: 0.3s ease-in-out;
  /* background: rgba(0, 0, 0, 0.432); */
  /* animation: animate 2s linear infinite; */
  /* bottom: 150px; */
}
.shapes2 li:nth-child(1) {
  z-index: 0;
  animation-delay: 0s;
  width: 50px;
  height: 26px;
  top: 5rem;
  left: 5rem;
  /* right: 75%;
      bottom: 25%; */
  background-size: contain;
  background-image: url("../../assets/images/Polygon19.svg");
  background-repeat: no-repeat;
}
.shapes2 li:nth-child(2) {
  z-index: 12;
  animation-delay: 0s;
  width: 50px;
  height: 26px;
  top: 10rem;
  right: 15rem;
  /* right: 4%;
      bottom: 30%; */
  background-size: contain;
  background-image: url("../../assets/images/Polygon19.svg");
  background-repeat: no-repeat;
}
.shapes2 li:nth-child(3) {
  z-index: 12;
  animation-delay: 0s;
  width: 50px;
  height: 28px;
  top: 20rem;
  right: 20rem;
  background-size: contain;
  background-image: url("../../assets/images/Polygon\ 15.svg");
  background-repeat: no-repeat;
}
.shapes2 li:nth-child(4) {
  z-index: 12;
  animation-delay: 0s;
  width: 50px;
  height: 28px;
  top: 10rem;
  left: 25%;
  background-size: contain;
  background-image: url("../../assets/images/Polygon\ 15.svg");
  background-repeat: no-repeat;
}
.shapes2 li:nth-child(5) {
  z-index: 12;
  animation-delay: 0s;
  width: 50px;
  height: 40px;
  top: 22rem;
  left: 10rem;
  /* right: 75%;
      bottom: 60%; */
  background-size: contain;
  background-image: url("../../assets/images/Ellipse\ 29.svg");
  background-repeat: no-repeat;
}
.shapes2 li:nth-child(6) {
  z-index: 12;
  animation-delay: 0s;
  width: 50px;
  height: 40px;
  top: 15rem;
  left: 5%;
  /* right: 85%;
      bottom: 13%; */
  background-size: contain;
  background-image: url("../../assets/images/Ellipse\ 30.svg");
  background-repeat: no-repeat;
}
.shapes2 li:nth-child(7) {
  z-index: 12;
  animation-delay: 0s;
  width: 50px;
  height: 40px;
  top: 18rem;
  left: 91%;
  background-size: contain;
  background-image: url("../../assets/images/Ellipse\ 30.svg");
  background-repeat: no-repeat;
}
.shapes2 li:nth-child(8) {
  z-index: 12;
  animation-delay: 0s;
  width: 50px;
  height: 40px;
  top: 5rem;
  right: 5rem;
  background-size: contain;
  background-image: url("../../assets/images/Ellipse\ 23.svg");
  background-repeat: no-repeat;
}
@keyframes animate2 {
  0% {
    transform: scale(100%) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: scale(200%) rotate(0deg);
    opacity: 1;
  }
}
.homePage .box_area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}
.box_area li {
  position: absolute;
  display: block;
  list-style: none;
  width: 100px;
  z-index: 1;
  height: 100px;
  background: rgba(0, 0, 0, 0.432);
  animation: animate1 20s linear infinite;
  bottom: -150px;
}
.box_area li:nth-child(1) {
  left: 86%;
  /* width: 80px;
    height: 80px; */
  animation-delay: 0s;
  background: url("../../assets/images/Polygon19.svg") no-repeat;
}
.box_area li:nth-child(2) {
  left: 12%;
  /* width: 30px;
    height: 30px; */
  background: url("../../assets/images/Polygon\ 15.svg") no-repeat;

  animation-delay: 1.5s;
  animation-duration: 10s;
}
.box_area li:nth-child(3) {
  left: 70%;
  /* width: 100px;
    height: 100px; */
  animation-delay: 5.5s;
  background: url("../../assets/images/Ellipse\ 23.svg") no-repeat;

  /* // animation-duration: 10s; */
}
.box_area li:nth-child(4) {
  left: 42%;
  width: 150;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 15s;
  background: url("../../assets/images/Ellipse\ 30.svg") no-repeat;
}
.box_area li:nth-child(5) {
  left: 65%;
  /* width: 40px;
    height: 40px; */
  animation-delay: 0s;
  background: url("../../assets/images/Ellipse\ 32.svg") no-repeat;

  /* // animation-duration: 10s; */
}
.box_area li:nth-child(6) {
  left: 15%;
  width: 110px;
  height: 110px;
  background: url("../../assets/images/Polygon\ 17.svg") no-repeat;

  animation-delay: 3.5s;
  /* // animation-duration: 10s; */
}
@keyframes animate1 {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translateY(-1000px) rotate(360deg);
    opacity: 0;
  }
}
.mainHeading {
  font-family: "Poppins", sans-serif;
  font-size: 6.6vw;
  font-style: normal;
  font-weight: 700;
  line-height: 85px;
  letter-spacing: 0em;
  position: absolute;
  right: 11%;
  opacity: 1;
  top: 170px;
  color: #ff8227;
  /* animation: typing 4s infinite ; */
  position: absolute;
}
.mainHeading::after {
  /* content: '';
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    height: 110%;
    border-right: 2px solid #000;
    overflow: hidden;
    width: 100%; */

  /* animation: typing 3s steps(10) infinite; */
}
.default-page-div {
  padding: 50px;
  width: 50%;
  margin: auto;
  margin-top: 180px;
  text-align: center;
}
.default-page-div h1 {
  font-size: 6rem;
}
.default-page-div p {
  font-size: 15x;
}
.default-page-div h6 {
  font-size: 1.8rem;
}
@keyframes typing {
  0% {
    /* left: 0%;
        width: 100%; */
    opacity: 0;
  }
  /* 50%{
        width: 30%;
    } */
  100% {
    /* left: 110%;
        width: 0%; */
    opacity: 1;
  }
}
.mainHeading1 {
  font-family: "Poppins", sans-serif;
  font-size: 9.6vw;
  font-style: normal;
  font-weight: 700;
  line-height: 159px;
  letter-spacing: 0em;
  position: absolute;
  right: 19%;
  top: 25%;
  color: #1d3883;
}
.mainHeading2 {
  font-family: "Poppins", sans-serif;
  font-size: 9.6vw;
  font-style: normal;
  font-weight: 700;
  line-height: 159px;
  letter-spacing: 0em;
  position: absolute;
  right: 13%;
  top: 40%;
  color: #0ab3de;
}
.subheading {
  font-family: "Poppins", sans-serif;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.1em;
  position: absolute;
  left: 53%;
  right: 10%;
  top: 465px;
  color: #000;
}
.subHeading1 {
  font-family: "Poppins", sans-serif;
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.1em;
  position: absolute;
  left: 53%;
  right: 10%;
  top: 555px;
  color: #000;
}
.yesHelp {
  font-family: "Poppins", sans-serif;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 600;
  /* letter-spacing: 0.1em; */
  box-shadow: 0rem 1rem 0.8rem rgba(0, 0, 0, 0.3) !important;
  position: absolute;
  left: 53%;
  right: 10%;
  top: 645px;
  background: #542b92;
  color: #fff;
}
.yesHelp:hover {
  color: #fff !important;
}
.designEventText {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}
.designEventDescription {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  width: 300px;
  word-break: break-word;
  font-style: normal;
  /* background: #000; */
  color: #000 !important;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
html {
  scroll-behavior: smooth;
}
.table thead th {
  vertical-align: baseline !important;
  border-bottom: 2px solid #dee2e6;
}
.read-more-less--more,
.read-more-less--less {
  display: flex;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.bg-light {
  background-color: var(--light) !important;
}

.bg-dark {
  background-color: var(--dark) !important;
}

.bg-gray {
  background-color: var(--gray) !important;
}

.bg-warning {
  background-color: var(--warning) !important;
}

.text-dark {
  color: var(--dark) !important;
}

.text-dark-gray {
  color: var(--dark-gray) !important;
}

.text-light {
  color: var(--light) !important;
}

.text-danger {
  color: var(--danger) !important;
}

.text-gray {
  color: var(--gray) !important;
}

.text-primary {
  color: var(--primary) !important;
}

.text-warning {
  color: var(--warning) !important;
}

.text-yellow {
  color: var(--yellow) !important;
}

body {
  font-family: "montserrat", sans-serif !important;
  padding-top: 0rem;
  margin: 0;
  padding: 0 !important;
}

h1,
.h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

h2,
.h2 {
  font-size: 2rem;
  font-weight: 700;
}

h3,
.h3 {
  font-size: 1.75rem;
  font-weight: 600;
}

h4,
.h4 {
  font-size: 1.5rem;
  font-weight: 600;
}

h5,
.h5 {
  font-size: 1.25rem;
  font-weight: 600;
}

h6,
.h6 {
  font-size: 1rem;
  font-weight: 600;
}

.btn-warning {
  background: rgb(255, 208, 97);
  border-color: var(--warning);
  background: linear-gradient(
    95deg,
    rgba(255, 208, 97, 1) 38%,
    rgba(237, 167, 70, 1) 100%
  );
}

.btn {
  transition: all 0.5s ease;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5 sease;
  border-width: 1.6px;
  border-radius: 6px;
}
.btn-primary {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}
.react-toggle--checked .react-toggle-track {
  background-color: var(--primary);
}
/* .sb-avatar::hover{

} */
.file-upload-btn:hover,
.file-upload-btn:hover:focus {
  background: #1d3883;
  box-shadow: none;
  outline: none;
  border: none;
  transition: all 0.2s ease;
  cursor: pointer;
}
/* .modal-open .main-container{
    -webkit-filter:blur(3px) grayscale(20%); 
} */
.modal.show {
  padding: 0 !important;
  backdrop-filter: blur(3px) grayscale(20%);
  overflow-x: hidden;
  overflow-y: auto;
}

/* .modal-backdrop{
    backdrop-filter: blur(5px);
    background-color: #01223770;
 }
 .modal-backdrop.in{
    opacity: 1 !important;
 } */
.file-upload-btn:active {
  border: 0;
  transition: all 0.2s ease;
}
.file-upload {
  display: inline-block;
  max-width: 250px;
  position: relative;
  border: none;
  outline: none;
  box-shadow: none;
}
.admin-card .card {
  min-width: 300px;
  transition: 0.3s;
}
.admin-card .card:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.aboutimageResize {
  /* width: 100%; */
  /* padding:0 2em !important; */
  width: 100%;
  height: 200px;
  object-fit: contain;
  /* height: 100%; */
}
.aboutimageResize1 {
  width: 100%;
  padding: 0 2em !important;
  height: 100%;
}

.file-upload-input {
  position: absolute;

  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  left: 0;
  cursor: pointer;
}

.profile-imgCont {
  height: 70px;
  width: 70px;
  text-align: center;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
/* .btn:hover {
    -o-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
} */

.cert-container {
  /* margin:65px 0 10px 0;  */
  width: max-content;
  /* display: flex; 
    justify-content: center; */
}

.cert {
  width: 800px;
  height: 552px;
  /* padding: 15px 20px; */
  text-align: center;
  position: relative;
  z-index: -1;
}

.cert-bg {
  position: absolute;
  left: 0px;
  top: 0;
  z-index: -1;
  width: 100%;
}
.certificateBorder{
 width: 808px;
  height: 536px;
  /* padding: 70px 60px 0px 60px; */
  text-align: center;
  
  border:20px solid #0a1b49;

}

.cert-content {
  width: 768px;
  height: 496px;
  padding: 10px 60px 0px 60px;
  /* text-align: center; */
  font-family: 'Lato', sans-serif;
  /* font-family: Arial, Helvetica, sans-serif; */
  border:15px solid #f5d95f;
}

.cert-content h3 {
  font-size: 30px;
  letter-spacing: 3px;
  padding-bottom: 5px;
  font-family: 'Lato', sans-serif;
}

.cert-content p {
  font-size: 25px;
  font-family: 'Lato', sans-serif;
}

.cert-content small {
  padding: 0 20px;
  font-size: 14px;
  line-height: 12px;
}

.bottom-txt {
  padding: 12px 25px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}

.bottom-txt * {
  white-space: nowrap !important;
}
.sign::before{
    content: "";
    border: 2px solid #f5d95f;
    position: absolute;
    width: 180px;
    right: 107px;
    bottom: 110px;
}
.other-font {
  padding-top: 20px;
  /* margin-top: 0px; */
  /* font-family: Cambria, Georgia, serif; */
  /* font-style: italic; */
}

.ml-215 {
  margin-left: 215px;
}
.btn-white {
  background-color: var(--white) !important;
  border-color: var(--white) !important;
  color: var(--primary) !important;
}

.btn-white:active,
.btn-white:hover {
  background-color: var(--primary) !important;
  border-color: var(--white) !important;
  color: var(--white) !important;
  border-width: 1.6px;
}

.btn-outline-primary {
  background-color: var(--primary);
  border-color: var(--white);
  color: var(--white);
  border-width: 1.6px;
}

.btn-outline-primary:hover {
  background-color: var(--white) !important;
  border-color: var(--primary);
  color: var(--primary);
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: var(--white);
  color: var(--primary);
  border-color: var(--primary);
}

.btn-outline-yellow {
  background-color: var(--white);
  border-color: var(--yellow);
  color: var(--yellow);
  border-width: 1.6px;
}

.btn-outline-yellow:hover {
  background-color: var(--yellow) !important;
  border-color: var(--yellow);
  color: var(--white);
}

.btn-outline-yellow:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-yellow.dropdown-toggle {
  background-color: var(--yellow);
  color: var(--white);
  border-color: var(--yellow);
}

.form-control-lg {
  height: calc(1.5em + 1rem + 8px);
}

.rounded {
  border-radius: 6px !important;
}

.rounded-lg {
  border-radius: 1rem !important;
}

.border-primary {
  border-color: var(--primary) !important;
}

.border-dark-gray {
  border-color: var(--dark-gray) !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-6 {
  font-size: 6px !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.Fweight-500 {
  font-weight: 500 !important;
}

.Fweight-600 {
  font-weight: 600 !important;
}

.btn-gray {
  background-color: #dcdcdc;
}

ul li {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.ulStyle{
  /* list-style: circle !important; */
  list-style-type: disc;
}

.cursor-pointer {
  cursor: pointer;
}

.line-H-2 {
  line-height: 2;
}

.line-H-12 {
  line-height: 1.5;
}

.line-H-1 {
  line-height: 1;
}

.line-H-9 {
  line-height: 0.9;
}

.K-font {
  font-family: "Kanit", sans-serif;
}

.mb-n6,
.my-n6 {
  margin-bottom: -6rem !important;
}

label {
  width: 100%;
  margin: 0 !important;
  padding: 0 6px;
}

.form-control {
  overflow: hidden;
}

a {
  display: block;
  white-space: pre-line;
  word-break: break-word;
}

img {
  vertical-align: middle;
  border-style: none;
  max-height: 100%;
}

.card-hvr {
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.bprder-w2 {
  border-width: 2px !important;
}
.card-hvr:hover {
  -o-transform: translateY(-6px);
  -ms-transform: translateY(-6px);
  -moz-transform: translateY(-6px);
  -webkit-transform: translateY(-6px);
  transform: translateY(-6px);
}
/* .sb-avatar{
    padding: 10px;
    background: red;
    margin: 10px;
    position: relative;
    top: 0px;
    left: 0px;
    width: 70px;
    height: 30px;
    color: #fff;
    font-family:Lato, "Lucida Grande", "Lucida Sans Unicode", Tahoma, sans-serif;
    cursor: pointer;
    border-radius: 6px;
  } */
.sb-avatar::before {
  content: "How it works?";
  position: absolute;
  top: 3px;
  right: -12px;
  transform: translateX(-50%);
  width: 157px;
  height: 40px;
  background: var(--primary);
  padding: 10px;
  color: #fff;
  text-align: center;
  line-height: 1.4;
  border-radius: 6px;
  transition: 0.5s;
  opacity: 0;
  z-index: 1;
}
.sb-avatar::after {
  content: "";
  position: absolute;
  top: 8px;
  right: 58px;
  transform: translateX(-50%) rotate(-51deg);
  transition: 0.5s;
  opacity: 0;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent var(--primary) var(--primary) transparent;
}

.sb-avatar:hover::after,
.sb-avatar:hover::before {
  opacity: 1;
}
.sb-avatar {
  position: fixed;
  bottom: 30px;
  right: 20px;
}
/* .D-height{min-height: 30px} */
/* header css */
header .navbar-nav li a {
  color: #fff !important;
  /* text-transform: uppercase;
    opacity: .8;
    font-size: .70rem;
    margin: 0;
    white-space: nowrap;
    line-height: 1;
    transition: none;
    font-weight: 600;
    background: var(--primary);
    padding: 6px 12px !important;
    transition: background-color .3s, color .3s, background .3s, box-shadow .1s ease-in-out;
    border-radius: 6px;
    letter-spacing: .8px; */
}
.navbar {
  background: #fff;
  box-shadow: 0rem 0.3rem 0.6rem rgba(0, 0, 0, 0.3) !important;
}
header .navbar-nav li a.nav-link {
  color: #1d3883 !important;
}
header .navbar-nav li a.nav-link.active,
header .navbar-nav li:hover a {
  opacity: 1;
  /* background: rgba(255, 255, 255, .27) */
  /* text-decoration: underline; */
  /* text-decoration-color: #FF8C5D !important; */
  color: #ff8c5d !important;
  padding-bottom: 10px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
}

.navbar-toggler {
  background: #1d3883;
}

.navbar-toggler:focus {
  /* outline: .5px solid #fff */
}

.navbar-brand img {
  height: 60px;
}

li.menu-img a {
  opacity: 1;
}

li.menu-img img {
  height: 20px;
}

/* home page content css */
.banner-img img {
  width: 355px;
  margin-bottom: -60px;
}

.top-banner {
  background: url(../../assets/images/background-img.png) no-repeat;
  min-height: 580px;
  background-size: cover;
  background-position: center bottom;
  background-color: #f8f9fb;
}
.badge-cont {
  height: 30px;
  width: 40px;
}
.badge-cont i.fa {
  top: -7px;
  right: -6px;
  display: none;
  cursor: pointer;
}
.badge-cont:hover i.fa {
  display: block;
}
.cust-search > .form-group {
  position: relative;
}

.cust-search > .form-group input::placeholder {
  color: var(--dark);
}

.cust-search > .form-group input {
  width: 100% !important;
  height: calc(1.5em + 0.75rem + 10px);
  font-size: 1.2rem;
  color: var(--dark);
  border: 1.5px solid var(--primary);
  border-radius: 35px;
}

.cust-search > .form-group::after {
  position: absolute;
  content: "\f002";
  font-family: "FontAwesome";
  right: 10px;
  color: var(--dark);
  z-index: 9;
  display: block;
  line-height: 3;
  font-size: 18px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.search input {
  background: #f0f0f0;
}

.search input::placeholder {
  color: #afaeb7;
  font-size: 16px;
  font-weight: 600;
}

.search::after {
  position: absolute;
  right: 0;
  content: "\f002";
  font-family: "FontAwesome";
  right: 10px;
  height: 25px;
  width: 25px;
  z-index: 9;
  display: block;
  top: 0;
  bottom: 0;
  margin: auto;
  color: var(--primary);
  opacity: 0;
}
.btn-secondary {
  /* letter-spacing: 1px; */
  color: #fff;
  /* font-weight: 700; */
  /* padding: 15px 6px; */
  text-transform: capitalize;
  /* width: 165px; */
  /* border-radius: 8px; */
  /* border: none; */
  background: rgba(29, 56, 131, 1);
  background: linear-gradient(
    to bottom,
    rgba(29, 56, 131, 1) 0%,
    rgba(14, 59, 186, 1) 100%
  );
  /* margin-right: 20px; */
}
.btn-dark-warning {
  color: #fed855;
  border: 1px solid #fed855;
  border-radius: 8px;
  padding: 10px 25px;
  letter-spacing: 0.94px;
  font-size: 12px;
  background-color: transparent;
  transition: all 0.5s ease;
  font-weight: 600;
}

.btn-wrng {
  background: rgb(254, 216, 85);
  background: linear-gradient(
    90deg,
    rgba(254, 216, 85, 1) 0%,
    rgba(254, 216, 85, 1) 35%,
    rgba(239, 170, 56, 1) 100%
  );
}

.btn-dark-warning:hover {
  background: #fed855;
  color: var(--dark);
}

.pagination .numbers button {
  color: #4a3081;
  cursor: pointer;
  border-radius: 6px;
  background-color: #fff;
  border: 2px solid #cacaca;
  padding: 5px 12px;
  margin-right: 12px;
  font-weight: 600;
}

.footer {
  background-color: #212529;
}

footer .container .navbar-nav li a {
  background: transparent;
  color: #fff !important;
  text-transform: uppercase;
  opacity: 0.8;
  font-size: 0.7rem;
  margin: 0px 10px;
  white-space: nowrap;
  line-height: 1;
  transition: none;
  font-weight: 900;
  padding: 6px 12px !important;
  transition: background-color 0.3s, color 0.3s, background 0.3s,
    box-shadow 0.1s ease-in-out;
  border-radius: 6px;
  letter-spacing: 0.8px;
}

footer .navbar-nav li a.nav-link.active,
footer .navbar-nav li:hover a {
  opacity: 1;
  background: transparent;
}

/* .footer .social_cont ul li:after {
  height: 4px;
  width: 10px;
  background-color: #ffd062;
  content: '';
  display: inline-block;
  margin: 25px 10px 25px -66px;
  transition: all 0.8s;
  opacity: 0;}
  .footer .social_cont ul li {
    display: inline-flex;
    margin: 0 15px;
} */
.footer .social_cont ul li:hover a i {
  color: #7978ee;
  border-color: #7978ee;
}

.footer .social_cont ul li a {
  text-decoration: none;
}

/* .footer .social_cont ul li a i {
    color: #fff;
    height: 45px;
    width: 45px;
    border: 2px solid #212529;
    border-radius: 50%;
    margin-right: 20px;
    font-size: 24px;
    transition: all 0.5s;
    display: flex;
    justify-content: center;
    align-items: center
} */

.footer .social_cont ul li:hover:after {
  opacity: 1;
  transition: all 0.5s;
  width: 45px;
  margin-left: 0;
  transition: all 0.8s;
}

/*how_it_work css start*/
.how_it_work {
  background-color: #f8f9fb;
  padding-top: 48px;
}

.how_it_work h2 {
  color: #212529;
}

.how_it_work .card {
  background-color: #fff;
  padding: 30px 25px;
  min-height: 300px;
  width: 100%;
  text-align: left;
  border: none;
  border-radius: 7px;
  box-shadow: 3px 0px 11px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.how_it_work .card-img {
  height: 50px;
}

.how_it_work .card p::-webkit-scrollbar {
  display: none;
}

.it_for_me .card p::-webkit-scrollbar {
  display: none;
}

.how_it_work .card p {
  color: #3e424e;
  max-height: 140px;
}

/*how_it_work css close*/

.it_for_me .title h2 {
  color: #fff;
  font-size: 34px;
}

.it_for_me .card {
  background-color: transparent;
}

.it_for_me_img_box img {
  max-height: 300px;
}

.it_for_me .card {
  width: 100%;
  height: 200px;
  margin-bottom: 30px;
  padding: 25px;
}

.it_for_me .card h3 {
  font-size: 34px;
  color: #ffd062;
  padding-bottom: 10px;
}

.it_for_me .card p {
  max-height: 100px;
  overflow: auto;
}

/*it_for_me css close*/

.ios_application {
  background: url("../../assets/images/ios-app-bg.jpg");
  min-height: auto;
  padding: 170px 0 170px 0;
}

.ios_application h3 {
  font-size: 24px;
  color: #646669;
}

.ios_application h2 {
  color: #212529;
  font-size: 34px;
  margin: 0;
  padding: 5px 0 10px 0;
}

.ios_application p {
  font-size: 20px;
  line-height: 28px;
  color: #1d2036;
}

.ios_application .ios_app-img-box {
  text-align: right;
}

.ios_application .ios_app-img-box img {
  margin-top: -100px;
}

.ios_application .btn {
  font-size: 18px;
  letter-spacing: 1px;
  color: #fff;
  font-family: "kanitbold";
  height: 60px;
  width: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  text-transform: uppercase;
  border: none;
  background: rgba(255, 208, 98, 1);
  background: linear-gradient(
    to right,
    rgba(255, 208, 98, 1) 0%,
    rgba(237, 167, 69, 1) 100%
  );
}

.thanksimg {
  height: 160px;
}
.upcoming_batch {
  background-color: #ecf3fe;
  background: linear-gradient(to bottom, #e8f1fe 0%, #ffffff 100%);
}
.upcoming_batch .card-header {
  height: 250px;
}
.upcoming_batch .card .card-body {
  padding: 20px 30px;
}
.slider {
  width: 100%;
}
.slider input {
  display: none;
}
.featured-slide-hover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: background-color 0.35s ease-in-out;
  -webkit-transition: background-color 0.35s ease-in-out;
}
.featured-slide {
  max-width: 100%;
  border-top-left-radius: 60px;
  border-bottom-right-radius: 60px;
}
.featured-slide:hover .linkdin-pos {
  display: block;
}
.linkdin-pos {
  display: none;
  font-size: 6vw;
  color: var(--primary);
  z-index: 9999999;
}
.featured-slide::before {
  content: "";
  padding-bottom: 120%;
  display: block;
}
.cust-border {
  border-top: 4px solid var(--primary);
  border-bottom: 4px solid var(--primary);
}
.featured-pos-bottom {
  /* position: absolute; */
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  /* background: rgba(29, 56, 131,.8) */
  background: var(--primary);
}
.featured-slide:hover .featured-slide-hover {
  background-color: rgba(255, 255, 255, 0.3);
}
area {
  color: #111;
  z-index: 42;
  position: absolute;
}
.testimonials {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 350px;
  perspective: 1000px;
  overflow: hidden;
}

.testimonials .item {
  top: 0;
  position: absolute;
  box-sizing: border-box;
  background-color: #0a0220;
  padding: 0;
  width: 450px;
  text-align: center;
  transition: transform 0.4s;
  /* -webkit-transform-style: preserve-3d; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  user-select: none;
  cursor: pointer;
}

.testimonials .item p {
  color: #ddd;
}
.testimonials .item h2 {
  font-size: 14px;
}
.dots {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dots1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dots label {
  display: block;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #413b52;
  margin: 7px;
  transition: transform 0.2s, color 0.2s;
}

/* First */
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: -35px !important;
}
.enroll_now {
  min-height: 40vh;
  width: 100%;
  margin-top: 20px;
}
.enroll_now .bg::before {
  content: "";
  position: absolute;
  width: 100%;
  /* height: 80%; */
  /* top: -50%;
    
    left: -50%; */
  /* z-index: -100000; */
  background: url(../../assets/images/review_back.png) 0 0 no-repeat;
  /* object-fit: fill; */
  /* background: url(background.png) 0 0 repeat; */
  /* transform: rotate(30deg); */
  background-size: cover;
}
/* .enroll_now .bg{
    background: url(../../assets/images/slider-background.png)no-repeat;
    height: 100vh;
    background-size: cover;
    background-position: center bottom;
    background-color: #f8f9fb;
} */
.enroll_now .title h2 {
  color: #212529;
  font-size: 34px;
}

.enroll_now .title p {
  color: #888;
  font-size: 20px;

  width: 68%;
  margin: 0 auto;
  padding: 10px 0 60px 0;
  line-height: 28px;
}

.enroll_now .item {
  min-height: auto;
  overflow: visible;
  padding-top: 80px;
  border-radius: 15px;
}
.enroll_now .owl-item .scales {
  background: white;
  color: black;
}
.owl-carousel .owl-stage {
  margin-top: 20px;
  /* margin-bottom: 20px; */
}
.enroll_now .owl-item.center .scales {
  transform: scaleY(1.3) scaleX(1.08);

  z-index: 1000;

  background: var(--primary) !important;
  color: white;
}

.enroll_now .owl-item.center .slidecontent {
  display: block;
}

.enroll_now .owl-nav {
  position: absolute;
  top: calc(24%);
  left: -100px;
  right: -100px;
}

.enroll_now .owl-prev {
  width: 78px;
  height: 78px;
  background: #eea947 !important;
  border-radius: 50%;
  outline: none;
  border: none;
  box-shadow: none;
}

.enroll_now .owl-prev:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.enroll_now .owl-next {
  width: 78px;
  height: 78px;
  background: #eea947 !important;
  border-radius: 50%;
  float: right;
  text-align: center;
}

.enroll_now .owl-next:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.enroll_now .owl-nav .owl-prev span {
  position: relative;
  font-size: 0;
}

.enroll_now .owl-nav .owl-prev span:before {
  content: "\f177";
  font-family: "FontAwesome";
  font-size: 21px;
}

.enroll_now .slidecontent {
  text-align: center;
  padding-bottom: 0;
  margin-top: 45px;
  display: none;
}

.enroll_now .slidecontent h3 {
  color: #eea947;
  font-size: 18px;
  margin-bottom: 0px;
}

.enroll_now .slidecontent p::-webkit-scrollbar {
  display: none;
}

.enroll_now .slidecontent p {
  color: #000;
  max-width: 350px;
  max-height: 80px;
  margin: 0 auto;
  line-height: 15px;
  overflow: auto;
  font-size: 10px;
  cursor: pointer;
}

.enroll_now .owl-nav .owl-next span {
  position: relative;
  font-size: 0;
}

.enroll_now .owl-nav .owl-next span:before {
  content: "\f178";
  font-family: "FontAwesome";
  font-size: 21px;
}

.enroll_now .proimg {
  border-radius: 50%;
  overflow: hidden;
  width: 70px;
  height: 70px;
  margin: -40px auto;
}

.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  border-top: 1px solid #4d5053;
  background-color: #212529;
}

.copyright p {
  font-size: 16px;
  color: #fff;
  text-align: center;
  margin: 0;
}

.innerbanner {
  position: relative;
  background-size: cover !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

section.innerbanner::after {
  content: "";
  display: block;
  padding-bottom: 22rem;
}

.innerbanner:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.innerbanner .breadcrumb {
  background: transparent;
  display: inline-block;
  padding: 0;
}

.breadcrumb-item.active {
  color: #fff !important;
  opacity: 1 !important;
  font-weight: 600 !important;
}

.innerbanner .breadcrumb .breadcrumb-item {
  display: inline-block;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  opacity: 0.8;
}

.innerbanner .breadcrumb a {
  color: #fff;
  text-decoration: none;
}

.innerbanner .breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
  content: "\f105";
  font-family: "FontAwesome";
}
.image-upload-wrap {
  cursor: pointer;
  border: 1px solid var(--color-greylight);
  background: url(../../assets/images/profileimage.png);
  background-position: top center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  position: relative;
  width: 200px;
  height: 170px;
  display: inline-block;
  background-color: #e4e6e7 !important;
  vertical-align: top;
}

.image-title-wrap {
  padding: 0 15px 15px 15px;
  display: none;
}
.image-upload-wrap > input {
  opacity: 0;
  position: absolute;
  width: 100%;
  cursor: pointer;
  left: 0;
  height: 100%;
  right: 0;
}
.upload-icon {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0;
  bottom: -18px;
  right: 0;
}
.upload-icon i {
  font-size: 17px;
}
.contantform .form-control {
  background: #f9fafc;
}
.abt-content .ltimg {
  height: 350px;
}

.workspace {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    306deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(248, 249, 251, 1) 100%
  );
}

.videosection iframe {
  border-radius: 75px;
  height: 32vw;
  max-width: 100%;
  width: 100vw;
}

.upcoming_batch .social-box ul li a {
  text-decoration: none;
  color: #1d3883;
  display: block;
}

.upcoming_batch .social-box ul li a i {
  color: #1d3883;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--primary);
  border-radius: 50%;
  font-size: 20px;
  margin-right: 15px;
  transition: all 0.5s;
}

.upcoming_batch .social-box ul li:hover a i {
  border-color: #6c90eb;
  color: #6c90eb;
}

.bluebar {
  background: #f9fafc;
  margin-bottom: 75px;
  overflow: hidden;
}

.bluebar ul li {
  float: left;
  width: calc(100% / 4);
  text-align: center;
  min-height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.bluebar ul li a {
  display: block;
  font-size: 25px;
  font-weight: 400;
  color: #212529;
  text-decoration: none;
  padding: 15px 0;
  width: 100%;
}

.bluebar ul li span {
  display: block;
  padding-bottom: 0px;
}
.bluebar ul li .fa {
  color: #ffd062;
  margin: 0 3px;
}
.bluebar ul li.active a {
  background: #1d3883;
  color: #fff;
}
.Candidatelist label.sortbylabel {
  font-size: 25px;
  font-weight: 500;
}
.Candidateprofile.card {
  border-radius: 0;
  border: none;
  position: relative;
}
.Candidateprofile.card .lockimg {
  position: absolute;
  left: calc(50% - 35px);
  top: 90px;
  display: none;
}
.Candidateprofile.card .card-body {
  margin-right: 20px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
  margin-top: 0;
  position: relative;
  z-index: 0;
  background: #fff;
  min-height: 200px;
}
.dis_H {
  height: 40px;
  overflow: auto;
}
.dis_H::-webkit-scrollbar {
  display: none;
}

.event-poster {
  height: 300px;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
}
.Candidateprofile.card .bluelabel {
  width: 105px;
  height: 32px;
  color: #fff;
  text-align: left;
  font-size: 15px;
  padding-left: 12px;
  font-weight: 600;
  line-height: 32px;
  display: inline-block;
  position: absolute;
  right: -40px;
  top: -14px;
  background-size: contain !important;
  z-index: 9999;
}
.hiremelabel {
  width: 100px;
  border-radius: 20px;
  height: 32px;
  color: #fff;
  text-align: center;
  font-size: 15px;
  padding-left: 12px;
  font-weight: 600;
  line-height: 32px;
  display: inline-block;
  position: absolute;
  right: -15px;
  top: -14px;
  background-color: rgb(4, 161, 4);
  background-size: contain !important;
  z-index: 9999;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.card-body .student-list div > h6.card-title {
  height: 20px;
  overflow: auto;
  word-break: break-word;
  white-space: normal;
}
.card-body .student-list div > p.card-text {
  height: 86px;
  overflow: auto;
  word-break: break-word;
  white-space: normal;
}
.card-body .student-list div > h6.card-title::-webkit-scrollbar,
.card-body .student-list div > p.card-text::-webkit-scrollbar {
  display: none;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}

.Candidateprofile.card .favlabel {
  position: absolute;
  right: 15px;
  top: 50px;
  cursor: pointer;
  z-index: 1;
}
.Candidateprofile.card .favlabel .img1 {
  display: none;
}
.Candidateprofile.card .favlabel .img2 {
  display: block;
}
.Candidateprofile.card .favlabel.active .img1 {
  display: block;
}
.Candidateprofile.card .favlabel.active .img2 {
  display: none;
}
.Candidateprofile.card .candiprogress .progress {
  height: 3px;
  background: #e8e8e8;
}
.Candidateprofile.card .candiprogress .progress-bar {
  background: #ffd062;
}
.Candidateprofile.card .candiprogress .w-50 {
  margin-bottom: 7px;
  float: left;
  padding-right: 40px;
}
.Candidateprofile.card .badgesbox {
  border-top: 1px solid #d8d8d86b;
  padding: 20px 0;
  border-bottom: 1px solid #d8d8d86b;
  overflow: hidden;
}
.Candidateprofile.card .badgesbox strong {
  font-weight: 500;
}
.candipagination {
  margin-top: 80px;
}
.candipagination li.page-item:first-child a {
  background: #212529;
  color: #fff;
  padding: 8px 15px;
}
.candipagination li.page-item:last-child a {
  letter-spacing: 1px;
  color: #fff;
  font-weight: 400;
  padding: 8px 30px;
  text-transform: uppercase;
  border: none;
  background: rgba(255, 208, 98, 1);
  background: linear-gradient(
    to right,
    rgba(255, 208, 98, 1) 0%,
    rgba(237, 167, 69, 1) 100%
  );
}
.candipagination li.page-item {
  margin: 0 14px;
}
.candipagination li.page-item a {
  font-weight: 400;
  font-size: 18px;
  border-radius: 4px !important;
  box-shadow: none;
  color: #212529;
}

.relatedstudents {
  padding: 140px 0;
}
.fulldetails.Candidateprofile.card {
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
}
.fulldetails.Candidateprofile.card .card-body {
  box-shadow: none;
}
.fulldetails.Candidateprofile.card .badgesbox {
  display: flex;
  width: 100%;
}
.fulldetails.Candidateprofile.card .badgesbox strong {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}
.fulldetails.Candidateprofile.card .badgesbox span.badge {
  font-size: 15px;
  min-width: 84px;
  height: 29px;
}
.fulldetails.Candidateprofile .contactinfo ul {
  margin-bottom: 20px;
}
/* .fulldetails.Candidateprofile .candiprogress h5 {
    font-size: 24px;
    font-weight: 600;
    margin: 40px 0 40px;
} */
.fulldetails.Candidateprofile.card .candiprogress .progress {
  height: 10px;
  background: #e8e8e8;
  border-radius: 25px;
}
.fulldetails.Candidateprofile.card .candiprogress label {
  display: block;
}
.fulldetails.Candidateprofile.card .candiprogress label span {
  float: right;
}
.fulldetails.Candidateprofile .contactinfo ul {
  opacity: 0.5;
}
.fulldetails.Candidateprofile .contactinfo.sociallinks ul {
  opacity: 1;
}
.fulldetails.Candidateprofile .contactinfo ul li {
  display: flex;
  margin-bottom: 20px;
}
.fulldetails.Candidateprofile .contactinfo ul li h6 {
  font-size: 20px;
  font-weight: 600;
}
.fulldetails.Candidateprofile .contactinfo ul li a {
  color: #000;
  font-weight: 500;
  text-decoration: none;
}
.fulldetails.Candidateprofile .contactinfo ul li .ctbox {
  display: inline-block;
  width: 81%;
  vertical-align: top;
}
.fulldetails.Candidateprofile.card .bluelabel {
  width: 110px;
  height: 36px;
  color: #fff;
  text-align: left;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 35px;
  display: inline-block;
  position: absolute;
  right: -30px;
  top: -21px;
}
.card_img {
  height: 270px;
  background-size: contain !important;
  background-position: top center !important;
  background-repeat: no-repeat !important;
  background-color: #e4e6e7 !important;
}
.dtpage .rightdetails {
  padding-left: 15px;
}
.modal-body div > input[type="radio"] {
  width: 25px;
  flex-shrink: 0;
  height: 25px;
}

.dtpage .rightdetails .btn-default img {
  margin-right: 10px;
}
.dtpage .rightdetails .desbox {
  border-left: 2px solid #ffd062;
  margin-left: 15px;
}
.dtpage .rightdetails .leftstar {
  padding-left: 40px;
  position: relative;
}
.dtpage .rightdetails .leftstar:before {
  background: #fff url("../../assets/images/Star.png") center center no-repeat;
  content: "";
  width: 24px;
  height: 28px;
  left: -14px;
  background-size: contain;
  top: 0;
  position: absolute;
}
.dtpage .rightdetails .content p {
  color: #7a7c7f;
  /* font-size: 18px; */
  font-weight: 500;
  white-space: normal;
  word-break: break-word;
  /* line-height: 2; */
}
.Certif-imgCont {
  width: 120px;
  display: block;
  height: 140px;
}
/* .dtpage .rightdetails .typpoject {padding: 40px 10px 10px 70px;} */

.tab-colr .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--white) !important;
  background-color: var(--primary) !important;
  border-color: #dee2e6 #dee2e6 #fff;
}
.tab-colr .nav-tabs .nav-link {
  color: var(--dark);
}
.relatedstudents h2 {
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 60px;
}
.relatedstudents .owl-nav {
  margin-top: 30px;
  width: 100%;
  text-align: center;
}
.relatedstudents .owl-carousel .owl-nav button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #eea947 !important;
  color: #fff !important;
  font-size: 0px;
  margin: 0 42px;
}
.relatedstudents .owl-carousel .owl-nav button:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.relatedstudents .owl-carousel .owl-nav button span {
  font-size: 0;
}
.relatedstudents .owl-carousel .owl-nav button.owl-prev span:before {
  content: "\f177";
  font-family: "FontAwesome";
  font-size: 24px;
}
.relatedstudents .owl-carousel .owl-nav button.owl-next span:before {
  content: "\f178";
  font-family: "FontAwesome";
  font-size: 24px;
}
.file-upload-field {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  width: 120px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  padding: 10px 12px;
  background-color: #4245a8;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}
.signupsection {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.signupsection .btn-primary {
  letter-spacing: 1px;
  color: #fff;
  font-weight: 600;
  padding: 15px 50px;
  text-transform: uppercase;
  border: none;
  background: rgba(255, 208, 98, 1);
  background: linear-gradient(
    to right,
    rgba(255, 208, 98, 1) 0%,
    rgba(237, 167, 69, 1) 100%
  );
}

.loginsection .borderbox a.linkedin .fa {
  border: 1.6px solid #1d3883;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-right: 22px;
  line-height: 56px;
  text-align: center;
  font-size: 38px;
}
.loginsection .borderbox .rightform .form-group.verified {
  position: relative;
}
.loginsection .borderbox .rightform .form-group.verified label {
  background: url(../../assets/images/verified.png) bottom right no-repeat;
  background-size: 18%;
}

.digit-group {
  text-align: center;
}
.digit-group input {
  min-height: 52px;
  border-bottom: 1px solid #828282 !important;
  color: #000;
  border: none;
  width: 45px;
  text-align: center;
  border-radius: 0px;
  margin: 0 5px;
}
/* table responsive */
.rwd-table {
  margin: auto;
  min-width: 300px;
  max-width: 100%;
  border-collapse: collapse;
}

.rwd-table thead tr:first-child {
  border-top: none;
  background: var(--primary);
  /* border-color: var(--warning); */
  /* background: linear-gradient(95deg, rgba(255, 208, 97, 1) 38%, rgba(237, 167, 70, 1) 100%); */
  color: #fff;
}

.rwd-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #f5f9fc;
}

.rwd-table tr:nth-child(odd):not(:first-child) {
  background-color: #ebf3f9;
}

.rwd-table th {
  display: none;
}

.rwd-table td {
  display: block;
}

.rwd-table td:first-child {
  margin-top: 0.5em;
}

.rwd-table td:last-child {
  margin-bottom: 0.5em;
}

.rwd-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 120px;
  display: inline-block;
  color: #000;
}

.rwd-table th,
.rwd-table td {
  text-align: left;
}

.rwd-table {
  color: #333;
  border-radius: 0.4em;
  overflow: hidden;
}

.rwd-table tr {
  border-color: #bfbfbf;
}

.rwd-table th,
.rwd-table td {
  padding: 0.5em 1em;
}

.imageresize1 {
  width: 30%;
  height: 100%;
}
.heading {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 76px;
  text-transform: capitalize;

  color: #1d3883;
}
.weexisttoempoweru {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 76px;
  text-transform: capitalize;
}
#blue-curve {
  z-index: -2;
  position: relative;
  width: 100%;
  height: 35vh;
  background-color: var(--primary);
  background-size: cover;
  display: flex;
  background-attachment: fixed;
  clip-path: circle(188vh at 50% -155vh);
  justify-content: center;
  align-items: center;
}
.company_name {
  font-family: Noto Sans;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
  word-wrap: break-word;

  letter-spacing: 0em;
  text-align: center;
  color: var(--primary);
}
.dropdown button:focus {
  outline: 0;
}

/* Footer Contact Form Stylling */
#foot {
  display: flex;
  justify-content: space-evenly;
}

.contact-form div {
  margin-top: 10px;
}
.contact-form h2 {
  color: white;
  margin-top: 20px;
}
.contact-form .row {
  margin-left: 50px;
  padding: 6px;
}
.col-md-6 input {
  padding: 10px;
}
.col-md-6 #name {
  margin-bottom: 22px;
}

#contact-form input {
  border: none;
  border-bottom: 1px solid white;
  width: 100%;
  background: transparent;
  color: white;
}

#contact-form textarea {
  width: 100%;
  border: none;
  border-bottom: 1px solid rgb(254 216 85);
  background: transparent;
  padding: 3px;
  color: white;
}
#contact-submit button {
  background-color: transparent;
  cursor: pointer;
  margin-top: 5px;
  margin-left: 32px;
}
#form-heading {
  font-size: 3em;
  margin-left: 2.9em;
  font-size: 48px;
  font-weight: 900;
  position: relative;
  top: 72px;
  left: 4px;
  /* margin-top: em !important;  */
}

#contact-submit button {
  cursor: pointer;
  color: rgb(254 216 85);
  border: 1px solid rgb(254 216 85);
  border-radius: 8px;
  padding: 10px 25px;
  letter-spacing: 0.94px;
  font-size: 12px;
  background-color: FACB4A;
  transition: all 0.5s ease;
  font-weight: 600;
  text-align: center;
  width: 15em;
  /* margin-left: 15em; */
  margin-top: 10px;
  outline: none;
}
#contact-submit button:hover {
  background-color: rgb(254 216 85);
  transition: all 0.5s ease;
  color: black;
}

#footer-contact-typ {
  margin-left: 12em;
  margin-top: 2.2em;
}

#footer-cntnt h2 {
  font-size: 1.7em;
}
#footer-icons {
  position: relative;
  top: 6em;
}

#footer-text {
  margin-top: 9em;
}
.nav-item {
  color: white;
  /* margin: 0 3%; */
}
.navfooter {
  display: flex;
  justify-content: center;
}
.footer .social_cont div a i {
  color: #fff;
  height: 45px;
  width: 45px;
  border: 2px solid #212529;
  border-radius: 50%;
  margin-right: 20px;
  font-size: 24px;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.social_cont {
  display: flex;
  justify-content: center;
}
#footer-cntnt-sub-heading {
  font-size: 15.6px;
}
.contact-nav-item {
  margin: 0 3%;
}

/* STYLE FOR ABOUT US PAGE */

.recImagesbox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.count {
  text-align: center;
  margin: 30px;
}
.count img {
  height: "200px";
  width: "50%";
}
.imageresize {
  height: 100%;
  width: 100%;
}

.imaggeRec {
  height: 6rem;
  width: 12rem;
}

.textStyle {
  font-family: Baloo Bhai 2;
  font-style: normal;
  font-weight: normal;
  /* background-color: blue; */
  background-size: cover;
  display: flex;
  background-attachment: fixed;
  clip-path: circle(183vh at 50% -153vh);
  justify-content: center;
  align-items: center;
}
#existToEmpower {
  text-align: center;
  /* z-index: 1000; */
  /* position: absolute; */
  margin-top: -7.2em;
}
#bluePart-h {
  /* z-index: 1; */
  color: black;
}
#bluePart-h h2 {
  /* z-index: 1; */
  /* color: black; */
  font-size: 1.8em;
}
.textAlignEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.textAlignStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
#about-cntnt {
  font-size: 1.5em;
  margin-top: 70px !important;
  /* font-weight: bold; */
  /* line-height: 60px; */
}
.rounded-blue {
  border-radius: 0 25% 25% 0;
  width: 80%;
  /* height: 50vh !important;  */
  /* -webkit-clip-path: circle(60.0% at 50% 10%); */
  clip-path: circle(140% at 0 0);
}
.typimage {
  margin-top: -80px !important;
}
.paragraph {
  font-family: "Baloo Bhai 2", cursive;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;

  color: rgba(0, 0, 0, 0.68);
}
/* .review-slider{
        position: relative !important;
        bottom: 205px !important;
    } */
.owl-theme {
  margin-top: 0px !important;
}
/* .slider-outer-container{
         height: 290px !important;
     } */
.owl-carousel .owl-stage-outer {
  height: 180px !important;
  overflow-x: clip !important;
  /* overflow-x: hidden !important; */
  overflow-y: visible !important;
}
/* .enroll_now {
         min-height: 60vh;
     } */
.enroll_now .bg::before {
  height: 40vh;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: -0px !important;
}
.owl-carousel .owl-stage {
  margin: 0;
}

.owl-stage-outer {
  height: 250px !important;
}
.owl-nav {
  display: flex;
  justify-content: space-between;
}
.enroll_now .owl-prev {
  width: 120px;
  height: 0px;
  background: none !important;
  color: rgb(33 125 186) !important;
}
.enroll_now .owl-next {
  width: 120px;
  height: 0px;
  background: none !important;
  color: rgb(33 125 186) !important;
}
.enroll_now .owl-nav .owl-next span:before {
  font-size: 40px !important;
  font-weight: 800 !important;
  content: "\f105";
  position: relative;
  bottom: 26px;
}
.enroll_now .owl-nav .owl-prev span:before {
  font-size: 40px !important;
  font-weight: 800 !important;
  content: "\f104";
  position: relative;
  bottom: 26px;
}
/* .enroll_now .owl-item.center{
      padding-top: 0px !important;
      margin-right:20px !important;
      margin-left: 20px !important;
  } */
.owl-carousel.owl-drag .owl-item {
  height: 0px !important;
}
.review-slider {
  /* position: relative;
      bottom: 760px; */
  z-index: 100;
}

.owl-theme img {
  border-radius: 50%;
}

.loginbackground {
  background-color: #1d3883;
  border-radius: 30px;
  border: none;
  color: white;
}
.loginbackground span {
  padding: 0px 15px;
  font-weight: bold;
}
.container1 {
  padding: 0 5%;
}

#heading-red {
  color: red;
}
#white-textarea::placeholder {
  color: white;
}
#interact-content {
  margin: 38px;
}
#interact-content p {
  position: relative;
  top: -14px;
}
.midcontentsection .col-12 {
  padding: 0;
}

#job-image {
  height: 200px;
}
#typ {
  /* margin-top: 90px; */
  margin-bottom: 200px;
}
#typ-content {
  margin: 41px;
}

#typ-content h3 {
  font-size: 1.25em;
  margin-bottom: -17px;
}
#job p {
  margin: 37px;
}
#how-typ-work img {
  position: relative;
  /* top: -45px; */
}
#how-typ-work h4 {
  font-size: 1.5em;
}

#we-open-door p {
  font-size: 1.33em;
  position: relative;
  /* top: -49px; */
}
#about-cntnt {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

/* .circle{
    clip-path: circle(200vh at 50% -153vh);
    background-color: #000;
} */

/*MEDIA QUERY FOR DEFAUT PAGE */
@media (max-width: 780px) {
  .default-page-div {
    padding: 0px;
    width: 100%;
    margin: auto;
    text-align: center;
    margin-top: 180px;
  }
}

/* MEDIA QUERY FOR ABOUT US PAGE */
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    height: auto !important;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-collapse {
    height: auto !important;
  }
}
@media only screen and (max-width: 375px) {
  #typ-image1 {
    height: 11em;
  }
  #typ-image2 {
    width: 13em;
  }
  #typ-image3 {
    width: 14em;
  }
}

@media only screen and (max-width: 320px) {
  #typ-image1 {
    height: 9em;
  }
  #typ-image2 {
    width: 11em;
  }
  #typ-image3 {
    width: 12em;
  }
}
#homepage_content {
  width: 100%;
  height: 100%;
  /* margin: 0; */
  margin-top: 70px;
  padding: 0;
  overflow-x: hidden;
}

@media (max-width: 567px) {
  .admin-card .card {
    min-width: 250px;
  }
  .enroll_now .owl-item.center .scales {
    transform: none;
  }
}

@media (min-width: 767px) {
  .Candidateprofile.card .card-body {
    margin-top: -45px !important;
  }
  .enroll_now .bg::before {
    height: 40vh;
  }
}
@media (min-width: 1224px) {
  .enroll_now .bg::before {
    /* height: 45vh !important; */
  }
}
@media screen and (max-width: 601px) {
  .event-poster {
    height: 200px;
  }
  .rwd-table tr:nth-child(2) {
    border-top: none;
  }
}
@media screen and (min-width: 600px) {
  .rwd-table tr:hover:not(:first-child) {
    background-color: #d8e7f3;
  }
  .rwd-table td:before {
    display: none;
  }
  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: 0.25em 0.5em;
  }
  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }
  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }
  .rwd-table th,
  .rwd-table td {
    padding: 1em !important;
  }
}

/*Responsive*/
/* @media only screen and(min-width:1320px){
    .enroll_now .bg::before{
        height: 40vh !important;
    }
} */
@media (max-width: 1610px) {
  .mainHeading {
    top: 170px;
    line-height: 120px;
  }
  .subheading {
    top: 550px;
  }
  .subHeading1 {
    top: 650px;
  }
  .yesHelp {
    top: 755px;
  }
}
@media (max-width: 1560px) {
  .fulldetails.Candidateprofile .contactinfo ul li .ctbox {
    display: inline-block;
    width: 75%;

    vertical-align: top;
  }
  .ios_application .ios_app-img-box img {
    margin-top: -50px;
  }
  .mainHeading {
    top: 170px;
    line-height: 120px;
  }
  .subheading {
    top: 550px;
  }
  .subHeading1 {
    top: 650px;
  }
  .yesHelp {
    top: 755px;
  }
}
@media (max-width: 1400px) {
  .mainHeading {
    top: 170px;
    line-height: 100px;
  }
  .subheading {
    top: 500px;
  }
  .subHeading1 {
    top: 610px;
  }
  .yesHelp {
    top: 695px;
  }
}

@media (min-width: 1600px) {
  .mainHeading {
    top: 230px;
    line-height: 120px;
  }
  .subheading {
    top: 610px;
  }
  .subHeading1 {
    top: 730px;
  }
  .yesHelp {
    top: 845px;
  }
  header button {
    font-size: x-large;
  }
  header ul {
    font-size: x-large;
  }
  .imageresize {
    padding: 10px 53px !important;
  }
}
@media (min-width: 1800px) {
  .mainHeading {
    top: 230px;
    line-height: 140px;
  }
  .subheading {
    top: 680px;
  }
  .subHeading1 {
    top: 800px;
  }
  .yesHelp {
    top: 915px;
  }
  header button {
    font-size: x-large;
  }
  header ul {
    font-size: x-large;
  }
  .imageresize {
    padding: 10px 53px !important;
  }
}
@media (min-width: 2000px) {
  .mainHeading {
    top: 260px;
    line-height: 163px;
  }
  .subheading {
    top: 800px;
  }
  .subHeading1 {
    top: 950px;
  }
  .yesHelp {
    top: 1070px;
  }
}
@media (min-width: 2400px) {
  .mainHeading {
    top: 280px;
    line-height: 180px;
  }
  .subheading {
    top: 840px;
  }
  .subHeading1 {
    top: 1000px;
  }
  .yesHelp {
    top: 1150px;
  }
}
@media (min-width: 991.99px) {
  /* .container, .container-lg, .container-md, .container-sm {
        max-width: 960px;
    } */
  /* .enroll_now .item {
        min-height: 550px;
        overflow: visible;
        padding: 0 0 20px 0;
    } */
  .abt-content .ltimg {
    height: 430px;
  }

  .it_for_me_img_box img {
    max-height: 420px;
  }

  .top-banner {
    min-height: 550px;
  }
}

@media (min-width: 1200px) {
  /* .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1280px;
    } */
  header .navbar-nav li a {
    margin: 0 5px;
  }

  .navbar-nav li a {
    margin: 0px 22px;
  }

  .workspace:after {
    position: absolute;
    content: "";
    width: 305px;
    height: 316px;
    background: url(../../assets/images/pattern.png) top left;
    bottom: 60px;
    right: 0;
  }

  .abt-content:after {
    position: absolute;
    content: "";
    width: 295px;
    height: 296px;
    bottom: -50px;
    left: 0;
    background: url(../../assets/images/pattern.png) top left;
  }

  .top-banner:after {
    position: absolute;
    content: "";
    background: url(../../assets/images/pattern.png) top left;
    width: 345px;
    height: 136px;
    bottom: -120px;
    right: 0;
  }
}
@media (max-width: 1324px) {
  .mainHeading {
    line-height: 100px;
    top: 150px;
    /* color: #FF8227; */
  }
  .subheading {
    top: 475px;
  }
  .subHeading1 {
    top: 555px;
  }
  .yesHelp {
    top: 630px;
  }
}

@media (max-width: 1200px) {
  h1,
  .h1 {
    font-size: calc(1.375rem + 1.5vw);
  }

  h2,
  .h2 {
    font-size: calc(1.325rem + 0.9vw);
  }

  h3,
  .h3 {
    font-size: calc(1.3rem + 0.6vw);
  }

  h4,
  .h4 {
    font-size: calc(1.275rem + 0.3vw);
  }

  .display-2 {
    font-size: calc(1.675rem + 5.1vw);
  }

  .display-3 {
    font-size: calc(1.575rem + 3.9vw);
  }

  .display-4 {
    font-size: calc(1.475rem + 2.7vw);
  }

  .bluebar ul li {
    float: left;
    width: calc(100% / 3);
  }

  .it_for_me .it_for_me_img_box {
    text-align: center;
  }
  .mainHeading {
    line-height: 90px;
    top: 150px;
    /* color: #FF8227; */
  }
  .subheading {
    top: 455px;
  }
  .subHeading1 {
    top: 535px;
  }
  .yesHelp {
    top: 610px;
  }

  /* .dtpage .rightdetails .btn-secondary,
    .dtpage .rightdetails .btn-primary,
    .dtpage .rightdetails .btn-danger {
        margin-right: 20px;
        padding: 15px 20px;
    } */
}

@media (max-width: 991.97px) {
  .videosection iframe {
    border-radius: 25px;
  }
  .typimage {
    margin-top: -30px !important;
  }
  .work-img {
    height: 350px;
  }

  .login-img {
    height: 350px;
  }

  header .navbar-nav li {
    width: 100%;
  }

  header .navbar-nav li a {
    border-bottom: 0.1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0;
    padding: 9px 8px !important;
    margin: 4px 0;
  }

  .footer-contnt .navbar-nav li a {
    border-bottom: 0;
  }

  .fulldetails.Candidateprofile.card {
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
  }
  .dtpage .rightdetails {
    padding-top: 50px;
  }
  /* .dtpage .rightdetails .content p {padding: 20px 00px 30px 55px;} */
}
@media (max-width: 1070px) {
  .mainHeading {
    line-height: 85px;
    top: 130px;
    /* color: #FF8227; */
  }
  .subheading {
    top: 415px;
  }
  .subHeading1 {
    top: 495px;
  }
  .yesHelp {
    top: 560px;
  }
}
@media (max-width: 992px) {
  .homePage {
    /* height: 80vh; */
    margin-top: 0;
  }
  .mainHeading {
    font-family: "Poppins", sans-serif;
    font-size: 6.6vw;
    /* right: 31%; */
    line-height: 66px;
    top: 170px;
    color: #ff8227;
  }
  .mainHeading1 {
    font-family: "Poppins", sans-serif;
    font-size: 9.6vw;
    font-style: normal;
    font-weight: 700;
    line-height: 159px;
    letter-spacing: 0em;
    position: absolute;
    right: 19%;
    top: 22%;
    color: #1d3883;
  }
  .mainHeading2 {
    font-family: "Poppins", sans-serif;
    font-size: 9.6vw;
    font-style: normal;
    font-weight: 700;
    line-height: 159px;
    letter-spacing: 0em;
    position: absolute;
    right: 13%;
    top: 35%;
    color: #0ab3de;
  }
  .subheading {
    font-family: "Poppins", sans-serif;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.1em;
    position: absolute;
    left: 53%;
    right: 10%;
    top: 385px;
    color: #000;
  }
  .subHeading1 {
    font-family: "Poppins", sans-serif;
    font-size: 0.9vw;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.1em;
    position: absolute;
    left: 53%;
    right: 10%;
    top: 455px;
    color: #000;
  }
  .yesHelp {
    font-family: "Poppins", sans-serif;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 600;
    /* letter-spacing: 0.1em; */
    box-shadow: 0rem 1rem 0.8rem rgba(0, 0, 0, 0.3) !important;
    position: absolute;
    left: 53%;
    right: 10%;
    top: 510px;
    background: #542b92;
    color: #fff;
  }
  .imageresize1 {
    width: 35%;
    height: 100%;
  }
}
@media (max-width: 868px) {
  .mainHeading {
    top: 150px;
    /* line-height: 160px; */
  }
  .subheading {
    top: 370px;
  }
  .subHeading1 {
    top: 420px;
  }
  .yesHelp {
    top: 470px;
  }
}
@media (max-width: 768px) {
  .textAlignEnd {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
  }
  .textAlignStart {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    align-items: center;
  }
  .homePage {
    /* height: 64vh; */
    margin-top: 0;
  }
  .mainHeading {
    font-family: "Poppins", sans-serif;
    line-height: 57px;
    /* right: 31%; */
    top: 150px;
    color: #ff8227;
  }
  .mainHeading1 {
    font-family: "Poppins", sans-serif;

    letter-spacing: 0em;
    position: absolute;
    /* right: 18%; */
    top: 21%;
    color: #1d3883;
  }
  .mainHeading2 {
    font-family: "Poppins", sans-serif;
    font-size: 9.6vw;
    font-style: normal;
    font-weight: 700;
    line-height: 159px;
    letter-spacing: 0em;
    position: absolute;
    right: 13%;
    top: 32%;
    color: #0ab3de;
  }
  .subheading {
    font-family: "Poppins", sans-serif;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.1em;
    position: absolute;
    left: 53%;
    right: 10%;
    top: 325px;
    color: #000;
  }
  .subHeading1 {
    font-family: "Poppins", sans-serif;
    font-size: 0.9vw;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.1em;
    position: absolute;
    left: 53%;
    right: 10%;
    top: 375px;
    color: #000;
  }
  .yesHelp {
    font-family: "Poppins", sans-serif;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 600;
    /* letter-spacing: 0.1em; */
    box-shadow: 0rem 1rem 0.8rem rgba(0, 0, 0, 0.3) !important;
    position: absolute;
    left: 53%;
    right: 10%;
    top: 435px;
    background: #542b92;
    color: #fff;
  }

  .ios_application h2 {
    font-size: 50px;
  }
  .ios_application .ios_app-img-box {
    text-align: center;
    margin: 20px 0;
  }
  .enroll_now .title p {
    width: 100%;
  }
  /* .enroll_now .owl-nav {display: none} */
  .ios_application .ios_app-img-box img {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  /* .container, .container-sm {
        max-width: 635px !important;
    } */
  #how-typ-work h4 {
    font-size: 1em;
  }
  #we-open-door p {
    font-size: 1em;
    position: relative;
    /* top: -49px; */
  }
  .paragraph {
    font-family: "Baloo Bhai 2", cursive;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    padding: 0 !important;

    color: rgba(0, 0, 0, 0.68);
  }
  .typimage {
    margin-top: -80px !important;
  }

  .banner-img img {
    width: 250px;
    margin-bottom: -110px;
  }

  .loginsection .borderbox .rightform .form-group.verified label {
    background: url(../../assets/images/verified.png) top right no-repeat;
    background-size: 26%;
  }

  .bluebar ul li {
    width: 100%;
  }
  .Candidatelist h5.showingresult {
    margin-top: 30px;
  }
  .candipagination li.page-item:last-child a {
    padding: 8px 10px;
  }
  .candipagination li.page-item a {
    font-size: 13px;
  }

  .innerbanner .breadcrumb .breadcrumb-item {
    display: inline-block;
    color: #fff;
    font-size: 21px;
    font-weight: 300;
  }
  .ios_application {
    padding: 70px 0 40px;
  }
  .upcoming_batch {
    padding: 40px 0;
  }
  /* .enroll_now .owl-item.center {transform: scale(1);padding-top: 0;} */
  .enroll_now .slidecontent p {
    color: #000;
    font-size: 14px;
    max-width: 100%;
    margin: 0 auto;
    line-height: 20px;
  }
  .footer .footer-contnt ul {
    margin-top: 20px;
  }
  .footer h2 {
    font-size: 24px;
  }
  .footer p {
    margin: 0;
    font-size: 16px;
  }

  .enroll_now .title p {
    font-size: 16px;
    line-height: 24px;
    padding-top: 0;
    padding-bottom: 30px;
  }
  /* .enroll_now {min-height: auto;} */
  .upcoming_batch .title h2,
  .enroll_now .title h2 {
    color: #212529;
    font-size: 30px;
  }
  .ios_application p {
    font-size: 16px;
    line-height: 24px;
    padding-top: 0;
  }
  .ios_application h2 {
    font-size: 30px;
    padding-bottom: 5px;
  }
  .ios_application h3 {
    font-size: 24px;
    color: #646669;
    margin: 0;
  }
  /* .enroll_now .owl-nav {display: none;} */
  .fulldetails.Candidateprofile.card {
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
  }
  .fulldetails.Candidateprofile .contactinfo ul li .ctbox {
    line-height: 20px;
    display: inline-block;
    width: 72%;
    vertical-align: top;
  }

  .fulldetails.Candidateprofile .contactinfo ul li a {
    font-size: 14px;
  }

  .fulldetails.Candidateprofile.card .badgesbox strong {
    font-size: 16px;
  }
  /* .dtpage .rightdetails .content {
        padding: 20px 00px 30px 20px;
    } */

  .dtpage .rightdetails .leftstar {
    padding-left: 32px;
  }

  /* .dtpage .rightdetails .content p {
        font-size: 14px;
        line-height: 22px;
    } */

  .dtpage .rightdetails .typpoject {
    padding-left: 30px;
    padding-top: 30px;
    padding-right: 0;
  }

  .relatedstudents .owl-carousel .owl-nav button {
    margin: 0 12px;
  }

  .relatedstudents {
    padding: 60px 0;
  }
}

@media (max-width: 767.99px) {
  .imageresize1 {
    width: 60%;
    height: 100%;
  }
  .enroll_now .bg::before {
    height: 40vh !important;
  }
  /* .container, .container-md, .container-sm {
        max-width: 800px;
    } */
  /* .enroll_now .slidecontent {padding-bottom: 120px;} */
}
@media (max-width: 700px) {
  .mainHeading {
    top: 130px;
    /* line-height: 160px; */
  }
  .subheading {
    top: 320px;
  }
  .subHeading1 {
    top: 360px;
  }
  .yesHelp {
    top: 400px;
  }
}
@media (max-width: 567px) {
  .registerPage {
    margin-top: 68px;
  }
  .shapes2 li {
    display: none;
  }
  .shapes li:nth-child(3) {
    left: 3rem;
  }
  .shapes li:nth-child(10) {
    right: 0;
  }
  .shapes li:nth-child(8) {
    display: none;
  }
  .shapes li:nth-child(7) {
    display: none;
  }
  .shapes li:nth-child(4) {
    display: none;
  }
  /* .enroll_now .bg::before {
        height: 50vh !important;
    } */
  .ReactModal__Content {
    /* width: 100% !important; */
    /* height: 80vh; */
  }
  .homePage {
    /* height: 64vh; */
    display: none;
  }
  .homepage1 {
    display: block;
  }
  .mainHeading {
    font-family: "Poppins", sans-serif;
    font-size: 9.6vw;
    font-style: normal;
    /* font-weight: 700; */
    /* line-height: 159px; */
    letter-spacing: 0em;
    position: absolute;
    right: 31%;
    top: 11%;
    color: #ff8227;
  }
  .mainHeading1 {
    font-family: "Poppins", sans-serif;
    font-size: 9.6vw;
    font-style: normal;
    font-weight: 700;
    line-height: 159px;
    letter-spacing: 0em;
    position: absolute;
    right: 18%;
    top: 21%;
    color: #1d3883;
  }
  .mainHeading2 {
    font-family: "Poppins", sans-serif;
    font-size: 9.6vw;
    font-style: normal;
    font-weight: 700;
    line-height: 159px;
    letter-spacing: 0em;
    position: absolute;
    right: 13%;
    top: 32%;
    color: #0ab3de;
  }
  .subheading {
    font-family: "Poppins", sans-serif;
    font-size: 3.2vw;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.1em;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    color: #000;
  }
  .subHeading1 {
    font-family: "Poppins", sans-serif;
    font-size: 3.2vw;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.1em;
    position: relative;
    left: 0;
    right: 0;
    top: 4%;
    color: #000;
  }
  .yesHelp {
    font-family: "Poppins", sans-serif;
    font-size: 3.2vw;
    font-style: normal;
    font-weight: 600;
    /* letter-spacing: 0.1em; */
    box-shadow: 0rem 1rem 0.8rem rgba(0, 0, 0, 0.3) !important;
    position: relative;
    left: 0%;
    right: 0%;
    top: 10%;
    background: #542b92;
    color: #fff;
    display: grid;
    margin: auto;
  }
  .floatingButton {
    display: grid;
    margin: auto;
  }

  .Modal.editModal {
    width: 100% !important;
  }
  .aboutimageResize {
    /* width: 80%; */
    /* padding:0 2em !important; */
    /* height: 100%; */
    /* display: block;
  margin-left: auto;
  margin-right: auto; */
  }
  .rounded-blue {
    border-radius: 0 25% 25% 0;
    width: 100%;
    /* height: 50vh !important;  */
    /* -webkit-clip-path: circle(60.0% at 50% 10%); */
    clip-path: circle(140% at 0 0);
  }
  #we-open-door p {
    font-size: 0.8em;
    position: relative;
    /* top: -49px; */
  }
  .paragraph {
    font-family: "Baloo Bhai 2", cursive;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    padding: 0 !important;
    color: rgba(0, 0, 0, 0.68);
  }
  .typimage {
    margin-top: -30px !important;
  }
  .open-button {
    right: -10px;
  }
  #homepage_content {
    width: 100%;
    height: 100%;
    /* margin: 0; */
    margin-top: 60px !important;
    padding: 0;
    overflow-x: hidden;
  }
  #about-cntnt {
    font-size: 1.5em;
    margin-top: 60px !important;
    /* font-weight: bold; */
    /* line-height: 60px; */
  }
  .enroll_now .bg::before {
    height: 40vh !important;
  }
  .owl-carousel .owl-stage-outer {
    height: 145x !important;
    overflow-x: clip !important;
    /* overflow-x: hidden !important; */
    overflow-y: visible !important;
  }
  .imageresize1 {
    width: 100%;
    height: 100%;
  }
  .container1 .section1 {
    margin-top: 0 !important;
  }
  #blue-curve {
    z-index: -2;
    position: relative;
    width: 100%;
    height: 35vh;
    background-color: var(--primary);
    background-size: cover;
    display: flex;
    background-attachment: fixed;
    clip-path: circle(138vh at 50% -105vh);
    justify-content: center;
    align-items: center;
  }
  .heading {
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    padding: 0 !important;
    margin-top: 5%;
    line-height: 46px;
    text-transform: capitalize;
    color: #1d3883;
  }
  .weexisttoempoweru {
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 76px;
    text-transform: capitalize;
  }
  .company_name {
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    /* white-space: wrap; */
    word-wrap: break-word;

    line-height: 46px;
    letter-spacing: 0.1em;
    text-align: center;
    color: var(--primary);
  }
  .dropdown-item {
    white-space: normal;
  }
  .sb-avatar {
    bottom: 20px;
    right: 10px;
  }
  .featured-slide {
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  .Certif-imgCont {
    height: 100px;
  }
  .how_it_work {
    padding: 0 !important;
    /* margin: 0!important; */
    /* margin-top: 2% !important; */
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-top: 50px !important;
  }

  .it_for_me_img_box img {
    max-height: 200px;
  }

  .login-img {
    height: 200px;
  }

  .work-img {
    height: 200px;
  }

  .it_for_me .card {
    height: 160px;
    margin-bottom: 20px;
  }

  .how_it_work .card {
    min-height: 245px;
    margin-bottom: 25px;
  }

  .banner-img img {
    width: 160px;
    margin-bottom: -40px;
  }

  .navbar-brand img {
    height: 40px;
  }

  .pagination .numbers button {
    padding: 2px 5px;
    margin-right: 7px;
    font-weight: 600;
  }

  .student_bg {
    height: 220px;
  }

  .abt-content .ltimg {
    height: 200px;
  }

  .navbar-toggler {
    padding: 5px;
  }

  .navbar-dark .navbar-toggler-icon {
    height: 20px;
  }

  .font-size-14 {
    font-size: 12px !important;
  }

  .line-H-2 {
    line-height: 1.5;
  }

  .upcoming_batch .card-header {
    height: 135px;
  }

  section.innerbanner::after {
    padding-bottom: 20rem;
  }

  .loginsection .borderbox a.linkedin .fa {
    width: 40px;
    height: 40px;
    margin-right: 12px;
    line-height: 37px;
    font-size: 22px;
  }

  .form-control-lg {
    height: calc(1.5em + 1rem + 2px);
  }

  .footer .social_cont ul li a i {
    margin-right: 10px;
  }

  .Candidateprofile.card .bluelabel {
    right: -32px !important;
    width: 80px;
    height: 27px;
    font-size: 13px;
    padding-left: 12px;
    line-height: 25px;
  }

  .fulldetails.Candidateprofile.card .bluelabel {
    right: -14px !important;
  }

  .card_img {
    height: 200px;
  }

  .fulldetails.Candidateprofile .contactinfo ul li {
    margin-bottom: 20px !important;
  }

  .fulldetails.Candidateprofile .contactinfo ul li .iconbox {
    margin-right: 8px !important;
  }

  .dtpage .rightdetails {
    padding-top: 30px !important;
    padding-left: 0 !important;
  }
  .imaggeRec {
    height: 1rem;
    width: 2rem;
  }
  .count img {
    width: 100%;
  }
}

/* media queries for footer contact form*/
@media only screen and (max-width: 1024px) {
  .contact-form .row {
    margin-left: 0;
    /* padding: 6px; */
  }
  #form-heading {
    margin-left: 0.5em;
  }
  #contact-submit button {
    margin-left: 0;
  }
}
@media (max-width: 1024px) {
  /* .enroll_now .bg::before{
        height: 40vh !important;
    } */
}
@media only screen and (max-width: 992px) {
  /* .imageresize1{
        width: 35%;
        height: 100%;
    } */
  /* .enroll_now .bg::before{
        height: 50vh !important;
    } */
  #footer-cntnt {
    margin-top: 0;
    /* position: relative; */
    /* top: 5em; */
  }
  #footer-text {
    margin-top: 0;
  }
  #foot {
    display: block;
  }
  #contact-form input {
    width: 100%;
  }
  #contact-form textarea {
    width: 100%;
  }
  #footer-icons {
    margin-left: 10em;
  }
  #footer-contact-typ {
    margin-left: -1em;
  }
}
@media only screen and (max-width: 768px) {
  #footer-cntnt {
    margin-top: 0;
    /* position: relative; */
    /* top: 5em; */
  }
  #footer-text {
    margin-top: 0;
  }
  #footer-contact-typ {
    max-width: 33em;
  }

  /* .enroll_now .bg::before{
        height: 50vh !important;
    } */
  .contact-form {
    /* text-align: center; */
    /* margin: 0 20px; */
  }
}
@media only screen and (max-width: 600px) {
  #responsive-contact-row {
    margin-right: 0 !important;
  }

  #contact-submit button {
    margin-left: 0;
  }
  /* #form-heading{
        margin-left: 1em;
    } */
}
@media only screen and (max-width: 426px) {
  #contact-submit {
    margin-left: 0em;
  }
  #footer-text {
    margin-top: 0;
  }
  .navfooter {
    padding-left: 0;
    flex-direction: column;
  }
  #footer-cntnt {
    margin-top: -2em;
  }

  .shapes li:nth-child(10) {
    right: 0;
  }
  .shapes li:nth-child(3) {
    left: 3rem;
  }
}
@media only screen and (max-width: 375px) {
  /* #contact-submit {
        margin-left: 2em;
    } */
  .company_name {
    font-family: "Poppins", sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    word-wrap: break-word;
    line-height: 46px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--primary);
  }
}

@media (max-width: 455px) {
  .weexisttoempoweru {
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 46px;
    text-transform: capitalize;
  }
}
@media only screen and (max-width: 375px) {
  #contact-form input {
    width: 100%;
  }
  #contact-form textarea {
    width: 100%;
  }
  #contact-submit {
    margin: auto;
  }
  .contact-form .row {
    margin-left: 0;
    padding: 6px;
  }
  #form-heading {
    margin-left: 0.5em;
  }
  #footer-text {
    margin-top: 0;
  }
  #footer-cntnt {
    margin-top: 0;
    /* position: relative; */
    /* top: 5em; */
  }
  #contact-submit button {
    margin-left: 0;
  }
}

@media only screen and (max-width: 320px) {
  #form-heading {
    font-size: 2.5em;
  }
  .contact-form {
    /* text-align: center; */
    margin: 0;
  }
  #contact-submit button {
    margin-left: 8px;
  }
}
/* @media (max-width:140px){
    .enroll_now .bg::before{
        height: 32vh;
    }
} */
.knowmoebutton {
  background-color: #ff8227;
  border: none;
  color: white;
  /* padding: 20px; */
  text-align: center;
  text-decoration: none;
  /* border: 2px solid #ff8227; */
  border-radius: 6px;
  padding: 5px;
  /* width: 20%; */
}
