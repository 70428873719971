.lead-pool-card{
    min-height : 100px ;
    width : 200px ;
    background-color: whitesmoke;
    border-radius: 6px ;
    border : 1px solid rgba(0, 0, 0, 0.1) ;
    box-sizing: border-box;
  
    position : relative ;
    overflow  :hidden ;
}

.lead-pool-card:hover{ 
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.1);
    transition: .2s all;
 }
