@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

body {
  font-family: "Montserrat", sans-serif;
}

.editorClassName{
  background-color: white;
  border-radius: 5px;
  
}


.header-db {
  background: #1d3883;
  padding: 4px 50px;
}

.header-db .navbar {
  background-color: transparent;
}

.header-db .navbar-expand-lg .navbar-collapse {
  justify-content: space-between;
}
.cursor-pointer {
  cursor: pointer;
}
.text-l-blue {
  color: #356efa;
}
.text-gray {
  color: #8e909a;
}
.text-blue {
  color: #1d3883;
}
.font-20 {
  font-size: 20px;
}
.font-18 {
  font-size: 18px;
}
.font-10 {
  font-size: 10px;
}
.font-12 {
  font-size: 12px;
}
.Fweight-500 {
  font-weight: 500;
}
.Fweight-600 {
  font-weight: 600;
}
.line-h-18 {
  line-height: 1.8;
}

.header-db span.userimg {
  width: 50px;
  height: 50px;
  display: inline-block;
  border: 2px solid #ffffff;
  padding: 5px;
  border-radius: 50%;
  margin-left: 5px;
}

.header-db span.userimg img {
  width: 100%;
  border-radius: 50%;
}

.header-db .notifications img {
  max-width: 35px;
}

.header-db .notifications .dropdown-toggle::after {
  display: none;
}

.header-db .form-inline {
  background-color: #fff;
  border-radius: 100px;
  margin-left: 100px;
}

.header-db .form-control {
  border-radius: 50px;
  outline: none;
  box-shadow: none;
  border: none;
  width: 360px;
  padding-left: 30px;
}

.header-db .btn {
  border: none;
  outline: none;
  margin-right: 15px;
  box-shadow: none;
  background-color: transparent;
  color: #808080;
}

.header-db .btn:hover,
.header-db .btn:focus {
  background-color: transparent;
  color: #808080;
}

.header-db .navbar-nav {
  align-items: center;
}
.header-db .navbar-light .navbar-nav .nav-link {
  color: #fff;
  font-weight: 600;
}

.header-db .badge {
  background: #ff0000;
  border: 2.42308px solid #1d3883;
  color: #fff;
  border-radius: 50%;
  margin-left: -22px;
  position: relative;
  top: -12px;
  width: 25px;
  height: 25px;
  font-size: 14px;
}

.header-db .navbar-light .navbar-nav .nav-item.dropdown {
  margin-left: 30px;
}

.header-db .notifications .dropdown-menu.show {
  width: 490px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.116013);
  right: -80px;
  left: auto;
  top: 70px;
  border: none;
  padding: 10px;
}

.header-db .notifications .dropdown-menu h5 {
  border-bottom: 1px solid #f4f4f4;
  padding: 25px;
}

.header-db .notifications .dropdown-menu h5 a.link {
  float: right;
  color: #1d3883;
  text-decoration: none;
}

.header-db .notifications .dropdown-menu.show:before {
  position: absolute;
  content: "";
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  right: 88px;
  content: "";
  top: -18px;
  border-top: none;
  border-right: 18px solid transparent;
  border-bottom: 18px solid;
  border-left: 18px solid transparent;
  border-bottom-color: #fff;
}

.header-db ul.notifications-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.header-db ul.notifications-list .badge {
  border: none;
  width: 50px;
  height: 20px;
  color: #fff;
  border-radius: 3px;
  background: #1d3883;
  color: #fff;
  font-weight: 400;
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 15px;
}

.header-db ul.notifications-list .contentb {
  padding-left: 10px;
}

.header-db ul.notifications-list h3 {
  font-size: 18px;
  margin-left: 20px;
  margin-top: 20px;
}

.header-db ul.notifications-list h6 {
  color: #3d3d3d;
}

.header-db ul.notifications-list p {
  color: #b1b1b1;
}

.header-db ul.notifications-list li {
  padding: 18px 25px;
  position: relative;
  border-bottom: 1px solid #f4f4f4;
  display: flex;
  align-items: flex-start;
}

.header-db ul.notifications-list p {
  margin-bottom: 0;
}
.desbox.L_side .sociallinks .dropdown.cust_notify .dropdown-menu.show {
  left: -100px !important;
}
.desbox.L_side .sociallinks .dropdown.cust_notify .dropdown-menu.show::after {
  left: 85px;
}
.desbox.L_side .dropdown.cust_notify .dropdown-menu.show {
  width: 450px;
  top: 40px !important;
  left: -38px !important;
}
.desbox.L_side .dropdown.cust_notify .dropdown-menu.show::after {
  left: 45px;
}
.desbox .dropdown.cust_notify .dropdown-menu.show {
  width: 600px;
  top: 60px !important;
  left: -45px !important;
  box-shadow: 0rem 0.1rem 2rem 0.01rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 8px;
  border: 0;
  will-change: unset !important;
  transform: unset !important;
}
.desbox .dropdown.cust_notify .dropdown-menu.show::after {
  position: absolute;
  content: "";
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  top: -20px;
  left: 25px;
  border-top: none;
  border-right: 25px solid transparent;
  border-bottom: 25px solid;
  border-left: 25px solid transparent;
  border-bottom-color: #fff;
}
.desbox .dropdown.cust_notify .dropdown-toggle::after {
  display: none;
}

.leftsidebar {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25377);
  min-height: 100vh;
  height: 100%;
  bottom: 0;
  position: absolute;
  top: 0;
  padding-top: 40px;
  left: 0;
}

.leftsidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.leftsidebar ul li a {
  display: block;
  padding: 25px 80px 25px 60px;
  text-decoration: none;
  border-left: 7px solid transparent;
  color: #747683;
}

.leftsidebar ul li a svg {
  margin-right: 20px;
}

.leftsidebar ul li.active a {
  background: #e9ecf3;
  display: block;
  border-left: 7px solid #1d3883;
}

.leftsidebar ul li.active a rect {
  fill: #1d3883;
}

.leftsidebar ul li.active a path {
  fill: #1d3883;
}

.leftsidebar ul li a rect {
  fill: #747683;
}

.leftsidebar ul li a path {
  fill: #747683;
}

.midcontentsection {
  /* margin-top: 70px; */
  position: relative;
}

.midcontentsection .profilebox {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25377);
  border-radius: 9px;
}

.midcontentsection .profilebox select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='50' viewBox='0 0 24 24' width='50' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 0px;
}
.midcontentsection .projectsdetails .btn-danger {
  font-size: 18px;
  padding: 8px 30px;
  outline: none;
  box-shadow: none;
  background: #ff8888;
  border: none;
  color: #3c3c3c;
  font-weight: 500;
}
.midcontentsection .projectsdetails .btn-default {
  font-size: 18px;
  margin-right: 30px;
  outline: none;
  box-shadow: none;
  padding: 8px 50px;
  background: #d8d8d8;
  border: none;
  color: #3c3c3c;
  font-weight: 500;
}

.file-upload-btn {
  margin: 30px 0 0;
  color: #fff;
  background: #1d3883;
  border: none;
  padding: 0;
  border-radius: 50px;
  border-bottom: none;
  transition: all 0.2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 400;
  width: 154px;
  height: 54px;
  font-size: 18px;
  line-height: 20px;
  outline: none;
}

button.file-upload-btn:focus {
  outline: none;
}

.drag-text {
  text-align: center;
}

.file-upload-image {
  max-height: 250px;
  max-width: 250px;
  margin: auto;
}

.remove-image {
  display: none;
  width: 200px;
  margin: 0;
  color: #fff;
  background: #cd4535;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid #b02818;
  transition: all 0.2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 600;
}

#dropFileForm img {
  margin: 15px 0;
}

#dropFileForm span {
  font-size: 20px;
  color: #3c3c3c;
}
.input-group-lg input::placeholder,
.input-group-lg textarea::placeholder {
  color: #bbbcc3;
}

#dropFileForm {
  margin: 0 0 30px 0;
  text-align: center;
  border-radius: 8px;
  overflow: hidden;
  transition: 0.5s;
}

#dropFileForm #fileLabel {
  border: 2px dashed #c8d1ff;
  display: block;
  padding: 25px;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
}

#dropFileForm #fileInput {
  display: none;
}

#dropFileForm #fileLabel:after,
#dropFileForm #fileLabel:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: -2;
  border-radius: 8px 8px 0 0;
}

#dropFileForm #fileLabel:before {
  z-index: -1;
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 5%,
    black 5%,
    black 10%
  );
  opacity: 0;
  transition: 0.5s;
}

#dropFileForm.fileHover #fileLabel:before {
  opacity: 0.25;
}
.relatedstudents {
  padding: 140px 0;
}

.fulldetails.Candidateprofile.card {
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
}

.fulldetails.Candidateprofile.card .badgesbox {
  display: flex;
  width: 100%;
}

.fulldetails.Candidateprofile.card .badgesbox strong {
  font-size: 24px;
  line-height: 30px;
  margin-right: 5px;
  font-weight: 600;
}

.fulldetails.Candidateprofile.card .badgesbox span.badge {
  margin-bottom: 4px;
  font-size: 20px;
  min-width: 94px;
  height: 29px;
}

/* 
.fulldetails.Candidateprofile .contactinfo h5 {
    font-size: 24px;
    font-weight: 600;
    margin: 40px 0 40px;
    font-family: 'Montserrat', sans-serif;
} */

.lockimg {
  height: 65px;
}
.lockimg img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  height: auto;
}
.fulldetails.Candidateprofile .contactinfo ul li a {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  text-decoration: none;
}

.fulldetails.Candidateprofile .contactinfo ul li .ctbox {
  display: inline-block;
  width: 81%;
  vertical-align: top;
}

.fulldetails.Candidateprofile .contactinfo ul li .iconbox {
  width: 37px;
  height: 37px;
  border: 2px solid #212529;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bluelabel .zoom {
  width: 123px !important;
  font-size: 20px !important;
  line-height: 37px !important;
  height: 46px !important;
}
/* 
.fulldetails.Candidateprofile.card .bluelabel {
    width: 138px;
    height: 42px;
    color: #fff;
    text-align: left;
    font-size: 24px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    line-height: 42px;
    display: inline-block;
    position: absolute;
    right: -30px;
    top: -21px;
    padding-left: 15px;
}
.rightdetails {
    padding-left: 15px;
} */
/* .rightdetails .btn-primary {
    letter-spacing: 1px;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    padding: 15px 25px;
    text-transform: uppercase;
    border: none;
    background: rgba(255, 208, 98, 1);
    background: linear-gradient(to right, rgba(255, 208, 98, 1) 0%, rgba(237, 167, 69, 1) 100%);
    margin-right: 40px;
} */

/* .rightdetails .btn-secondary {
    letter-spacing: 1px;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    padding: 15px 25px;
    text-transform: uppercase;
    border: none;
    background: rgba(29, 56, 131, 1);
    background: linear-gradient(to bottom, rgba(29, 56, 131, 1) 0%, rgba(14, 59, 186, 1) 100%);
    margin-right: 40px;
} */

/* .rightdetails .btn-default {
    letter-spacing: 1px;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    padding: 15px 25px;
    text-transform: uppercase;
    border: none;
    background: rgba(74, 74, 74, 1);
    background: linear-gradient(to bottom, rgba(74, 74, 74, 1) 0%, rgba(112, 112, 112, 1) 100%);
    margin-right: 0px;
} */

.rightdetails .btn-default img {
  margin-right: 10px;
}

.rightdetails .desbox {
  margin-top: 95px;
  border-left: 2px solid #ffd062;
  margin-left: 15px;
}

.rightdetails h3.leftstar {
  padding-left: 70px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 27px;
  position: relative;
}

.rightdetails h3.leftstar:before {
  background: #fff url(../../assets/images/Star.png) center center no-repeat;
  content: "";
  width: 33px;
  height: 33px;
  left: -18px;
  top: 0;
  position: absolute;
}
/* .rightdetails .content{
    padding: 20px 70px 80px 55px;
    margin-left: 15px;
} */
/* .rightdetails .content p{
    color: #7a7c7f;
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    line-height: 42px;
} */

.rightdetails .typpoject {
  padding: 40px 70px;
}
.chatside h2 {
  line-height: 49px;
  letter-spacing: 0.8px;
  color: #212529;
  margin: 50px 0 40px;
}

.chatside h6 {
  font-size: 24px;
  color: #3d3d3d;
  margin-bottom: 15px;
}

.chatside .chatleftbox {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.116013);
  border-radius: 8px;
  padding: 35px;
}
.chatside .fixedheader {
  margin: -35px -35px 0 -35px;
  padding: 35px;
  overflow: hidden;
}

.chatside .chatleftbox .form-inline {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #979797;
  position: relative;
}

.chatside .chatleftbox .form-control {
  border-radius: 50px;
  outline: none;
  box-shadow: none;
  border: none;
  width: 100%;
  padding-left: 15px;
  min-height: 60px;
}

.chatside .chatleftbox .btn {
  border: none;
  outline: none;
  margin-right: 15px;
  box-shadow: none;
  background-color: transparent;
  color: #3d3d3d;
  position: absolute;
  right: 0px;
}

.chatside .chatleftbox .btn:hover,
.chatside .chatleftbox .btn:focus {
  background-color: transparent;
  color: #808080;
}

.chatside ul.chatlist {
  padding: 0;
  list-style: none;
  margin: 0;
  overflow-y: scroll;
  height: 500px;
}

.chatside ul.chatlist .userimg {
  display: inline-block;
  width: 99px;
  height: 99px;
  vertical-align: top;
  margin-right: 15px;
}

.chatside ul.chatlist .userimg img {
  width: 99px;
  height: 99px;
}

.chatside ul.chatlist li {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  padding: 10px 0;
}

.chatside ul.chatlist .userdt {
  display: inline-block;
  position: relative;
  width: calc(100% - 114px);
}

.chatside ul.chatlist .userdt h6 {
  margin-bottom: 0;
}

.chatside ul.chatlist .userdt p {
  color: #aaaaaa;
  margin-bottom: 0;
  width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.chatside ul.chatlist .userdt .badge {
  position: absolute;
  right: 0;
  color: #aaaaaa;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  top: 30px;
}

.chatside .chatrightbox h6 {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 18px;
}

.chatside .chatrightbox {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.116013);
  border-radius: 8px;
  padding: 35px;
}

.chatside .chatrightbox ul {
  list-style: none;
  padding: 30px 0 0 0;
  margin: 0;
  height: 480px;
  overflow-y: scroll;
}

.chatside .chatrightbox ul .userimg {
  display: inline-block;
  width: 90px;
  height: 90px;
  margin-right: 25px;
}

.chatside .chatrightbox ul li {
  padding: 25px 0;
  display: block;
  width: 100%;
  overflow: hidden;
}

.chatside .chatrightbox ul .userdt {
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding-top: 5px;
}

.chatside .chatrightbox ul .userdt p {
  background: #1d3883;
  border-radius: 15.2733px;
  margin: 0;
  padding: 15px 35px 15px 15px;
  font-size: 14px;
  color: #fff;
  width: 342px;
  text-align: left;
  position: relative;
}

.chatside .chatrightbox ul .userdt p:after {
  position: absolute;
  content: "";
  width: 30px;
  height: 30px;
  background: url(../../assets/images/leftshape.svg) top center no-repeat;
  left: -5px;
  background-size: 100%;
  bottom: -16px;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.chatside .chatrightbox ul .userdt .badge {
  color: #747683;
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  right: 0;
  bottom: -40px;
}

.chatside .chatrightbox ul .userdt .badge img {
  margin-right: 5px;
  max-width: 22px;
  margin-top: -3px;
}

.chatside .chatrightbox ul li.rt1 {
  float: right;
  text-align: right;
}

.chatside .chatrightbox ul li.rt1 .userimg {
  float: right;
  margin-right: 0;
  margin-left: 25px;
}

.chatside .chatrightbox ul li.rt1 .userdt p {
  background: #747683;
}

.chatside .chatrightbox ul li.rt1 p:after {
  background: url(../../assets/images/rightshape.svg) top center no-repeat;
  right: -5px;
  left: auto;
  background-size: 100%;
  -moz-transform: scaleX(+1);
  -o-transform: scaleX(+1);
  -webkit-transform: scaleX(+1);
  transform: scaleX(+1);
}

.chatside .chatrightbox h5 {
  color: #b1b1b1;
  font-weight: 400;
  padding: 15px 0;
  margin-bottom: 0;
}

.chatside .msgsendbar {
  margin: 0 -35px -35px -35px;
  background: #353535;
  border-radius: 0px 0px 13px 13px;
  padding: 18px 25px;
}

/* .chatside .msgsendbar .form-inline {} */

.chatside .msgsendbar .form-inline {
  width: calc(100% - 50px);
  display: inline-block;
  position: relative;
  padding-left: 25px;
}

.chatside .msgsendbar .form-control {
  background: #ffffff;
  border-radius: 23px;
  min-height: 46px;
  width: calc(100% - 80px);
}

.chatside .msgsendbar .btn {
  border: none;
  outline: none;
  margin-right: 15px;
  box-shadow: none;
  background-color: transparent;
  color: #3d3d3d;
  position: absolute;
  right: 0px;
  background: linear-gradient(145.62deg, #ff8227 25.76%, #ffa325 95.16%);
  width: 45px;
  height: 45px;
  margin-right: 0;
  border-radius: 50%;
}

.chatside .msgsendbar .btn:hover,
.msgsendbar .chatside .btn:focus {
  background-color: transparent;
  color: #808080;
}

.chatside .custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.chatside .custom-file-input {
  width: 40px;
  cursor: pointer;
}

.chatside .filebutton {
  display: inline-block;
  width: 40px;
  overflow: hidden;
  vertical-align: middle;
}

.chatside .filebutton label {
  margin: 0;
  margin-top: px;
  cursor: pointer;
  position: absolute;
  display: block;

  background: url(../../assets/images/plussign.svg);

  width: 40px;
  height: 40px;
  background-size: 100%;

  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;

  z-index: 1;
}

/*Responsive*/

@media (max-width: 1560px) {
  .fulldetails.Candidateprofile .contactinfo ul li .ctbox {
    display: inline-block;
    width: 75%;

    vertical-align: top;
  }
}

@media (max-width: 1440px) {
  .leftsidebar ul li a {
    padding: 25px 30px 25px 30px;
  }
}

@media (max-width: 1280px) {
  .rightdetails .btn {
    margin-right: 15px;
  }

  .fulldetails.Candidateprofile.card .badgesbox span.badge {
    margin-bottom: 10px;
    font-size: 15px;
    min-width: 84px;
    margin-right: 6px;
    height: 29px;
  }

  .leftsidebar ul li a {
    padding: 25px 20px 25px 20px;
  }

  .chatside .chatrightbox ul li.rt1 .userimg {
    float: right;
    margin-right: 0;
    margin-left: 15px;
  }

  .chatside .chatrightbox {
    padding: 15px;
  }

  .chatside .chatrightbox ul .userimg {
    margin-right: 10px;
  }
}

@media (max-width: 1199px) {
  .leftsidebar ul li a {
    padding: 25px 15px 25px 15px;
  }

  .leftsidebar ul li a svg {
    margin-right: 10px;
  }

  .chatside .chatrightbox ul .userimg {
    margin-right: 15px;
  }

  .header-db {
    background: #1d3883;
    padding: 8px 0;
  }

  .header-db .form-inline {
    background-color: #fff;
    border-radius: 100px;
    margin-left: 50px;
  }
}

@media (max-width: 1199px) {
  .rightdetails .btn {
    margin-right: 10px;
    font-size: 14px;
    padding: 10px 10px;
  }

  .rightdetails .btn-default img {
    max-width: 15px;
  }

  .fulldetails.Candidateprofile.card .badgesbox strong {
    font-size: 16px;
  }

  .fulldetails.Candidateprofile.card .badgesbox span.badge {
    margin-bottom: 4px;
    font-size: 12px;
    min-width: 68px;
    height: 20px;
  }

  /* .fulldetails.Candidateprofile .contactinfo h5 {
        margin-top: 0;
        font-size: 20px;
        margin-bottom: 15px;
    } */
  .fulldetails.Candidateprofile .contactinfo ul li h6 {
    font-size: 16px;
    margin-bottom: 0;
  }

  .fulldetails.Candidateprofile .contactinfo ul li a {
    font-size: 14px;
  }

  .fulldetails.Candidateprofile .contactinfo ul li .ctbox {
    width: 73%;
  }
}

@media (max-width: 990px) {
  .desbox.L_side .sociallinks .dropdown.cust_notify .dropdown-menu.show {
    width: 80vw;
    left: auto !important;
    max-width: 500px;
    right: 0 !important;
  }
  .header-db .form-inline {
    margin-left: 0;
  }
  .header-db .form-control {
    width: 100%;
  }
  .header-db .btn {
    position: absolute;
    right: 15px;
    z-index: 99;
  }
  .header-db .navbar-light .navbar-brand img {
    width: 100%;
  }
  .header-db .navbar-collapse {
    margin-top: 14px;
  }
  .header-db .navbar-nav .nav-item.dropdown.notifications {
    margin-top: 10px;
  }
  .header-db .navbar-light .navbar-nav .nav-link {
    text-align: right;
  }
  .header-db .navbar-nav .dropdown-menu {
    position: absolute;
    float: none;
  }
  .header-db .navbar-nav {
    margin-top: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: inherit;
  }
  .header-db .notifications .dropdown-menu.show {
    width: 490px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.116013);
    right: auto;
    left: -80px;
    top: 60px;
    border: none;
    padding: 10px;
  }
  .header-db .notifications .dropdown-menu.show:before {
    left: 80px;
    right: auto;
  }
  .header-db .navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
    max-width: 100px;
  }

  .chatside h2 {
    line-height: 49px;
    letter-spacing: 0.8px;
    color: #212529;
    padding-left: 15px;
    margin: 30px 0 20px;
  }

  .chatside .fixedheader {
    margin: -15px -15px 0 -15px;
    padding: 15px;
    overflow: hidden;
  }

  .chatside .chatleftbox {
    padding: 15px;
  }

  .chatside ul.chatlist .userimg img {
    width: 70px;
    height: 70px;
  }

  .chatside ul.chatlist .userimg {
    display: inline-block;
    width: 70px;
    height: 70px;
    vertical-align: top;
    margin-right: 15px;
  }

  .chatside ul.chatlist .userdt h6 {
    margin-bottom: 0;
    font-size: 16px;
  }

  .chatside .chatrightbox ul .userimg {
    width: 70px;
    height: 70px;
    margin-right: 15px;
  }

  .chatside {
    margin-top: 110px;
  }
  .leftsidebar {
    width: 100%;
    background: #fff;
  }

  .leftsidebar ul {
    overflow: hidden;
  }

  .leftsidebar ul li {
    float: left;
    width: 25%;
  }

  .leftsidebar ul li a {
    font-size: 0;
    border-bottom: 3px;
    text-align: center;
  }

  .leftsidebar ul li a svg {
    margin-right: 0;
  }

  .leftsidebar ul li.active a {
    background: #e9ecf3;
    display: block;
    border-left: none;
    border-bottom: 3px solid #1d3883;
  }

  .leftsidebar {
    width: 100%;
    background: #fff;
    top: 0;
    min-height: 74px;
    padding: 0;
    z-index: 999;
    border-radius: 0;
  }
}

@media (max-width: 767px) {
  .fulldetails.Candidateprofile.card .bluelabel {
    width: 108px;
    height: 30px;
    line-height: 33px;
    font-size: 18px;
  }
  .desbox .dropdown.cust_notify .dropdown-menu.show {
    max-width: 600px;
    width: 90vw;
  }
  .desbox.L_side .dropdown.cust_notify .dropdown-menu.show {
    max-width: 600px;
    width: 84vw;
  }
  .dtpage .rightdetails .desbox {
    margin-left: 5px;
  }
  .header-db .notifications .dropdown-menu.show {
    width: 410px;
  }
  .midcontentsection .rightdetails .btn {
    margin-right: 0;
    width: 100%;
    margin-bottom: 10px;
  }

  .rightdetails .typpoject h3 {
    font-weight: 600;
    font-size: 22px;
    margin-top: 15px;
  }

  .midcontentsection .rightdetails .typpoject {
    padding-left: 30px;
    padding-top: 30px;
    padding-right: 0;
  }

  .midcontentsection .rightdetails .typpoject p {
    font-size: 14px;
    line-height: 22px;
  }

  .midcontentsection .rightdetails .typpoject {
    padding-left: 30px;
    padding-top: 30px;
    padding-right: 0;
  }
  /* .midcontentsection .rightdetails .content{padding: 8px 00px 30px 20px;} */
  /* .midcontentsection .rightdetails .content p{
        
        font-size: 14px;
        line-height: 22px;
    } */

  .midcontentsection .rightdetails h3.leftstar {
    padding-left: 32px;
  }

  .midcontentsection .rightdetails {
    padding-top: 50px;
  }
  .fulldetails.Candidateprofile.card {
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
  }
  .chatside .chatrightbox ul .userdt p {
    width: 260px;
  }

  .chatside .msgsendbar {
    margin: 0 -15px -15px -15px;
    background: #353535;
    border-radius: 0px 0px 13px 13px;
    padding: 18px 15px;
  }

  .chatside .chatleftbox .form-control {
    min-height: 40px;
  }

  .chatside .msgsendbar .form-control {
    display: inline-block;
  }

  .chatside .msgsendbar .form-inline {
    padding-left: 15px;
  }

  .chatside .msgsendbar .btn {
    margin: 0 !important;
  }

  .chatside .msgsendbar .form-control {
    width: calc(100% - 60px);
  }

  .chatside .chatrightbox ul li {
    padding: 25px 0 38px;
    display: block;
    width: 100%;
    overflow: hidden;
  }

  .image-upload-wrap {
    width: 200px;
    height: 200px;
  }
  .file-upload-image {
    width: 100%;
    height: 100%;
  }

  .file-upload {
    margin-bottom: 10px;
    display: inline-block;
    max-width: 280px;
  }
}

.login-modal {
  width: 400px;
}

@media (max-width: 580px) {
  .login-modal {
    width: 350px;
    margin: 20px auto;
  }
}

.admin-form-btn:hover {
  color: rgb(29, 56, 131);
  background-color: white;
}

@media (max-width: 400px) {
  html,
  body {
    width: 100%;
    overflow-x: hidden;
  }
  .admin-outer-container {
    display: block !important;
  }
  .admin-blue-container {
    width: 100% !important;
    height: 309px !important;
  }
  .admin-form-img {
    width: 200px !important;
    height: 155px !important;
    bottom: 208px !important;
    left: 216px !important;
  }
  /* .row {
    display: block !important;
    width: 380px !important;
    padding: 0px 20px !important;
  } */
  .drive-container {
    display: none !important;
  }
  .admin-white-container {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }
  .admin-blue-container p {
    font-size: 18px !important;
    line-height: 2.5 !important;
    margin-bottom: 0px !important;
  }
  .admin-blue-container span {
    font-size: 12px !important;
  }
  .admin-blue-paragraph-container {
    width: 300px !important;
    letter-spacing: 0.3vw !important;
  }
  .placement-container {
    border-radius: 0px 35px 35px 0px !important;
    bottom: 100px !important;
  }
  .admin-form-btn {
    margin-left: 115px !important;
  }
}

.login-modal {
  width: 400px;
}

@media (max-width: 580px) {
  .login-modal {
    width: 350px;
    margin: 20px auto;
  }
}
